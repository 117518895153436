const initialState = false;

export default function loanDataReducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_LOAN_DATA':
      return action.payload
    case 'RESET_LOAN_DATA':
      return false

    default:
      return state;
  }
}