import { Button, Layout, Space, Spin, Tooltip } from "antd";
import { Header } from "antd/es/layout/layout";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getintrestRates } from "../../actions/Actions";
import logo from "../../images/FP_Logo.png";
import LoanDetails from "../LoanDetails/LoanDetails";

function GuestLoanDeatils() {
  const dispatch = useDispatch();
  const loanData = useSelector((state) => state.loanData);
  const requestSent = useSelector((state) => state.requestEntry.requestSent);
  const loanAccess = useSelector((state) => state.requestEntry.loanAccess);
  const intrestRates = useSelector((state) => state.requestEntry.intrestRates);
  const [rate, setRate] = useState(false);
  const [ltv, setLTV] = useState(false);

  return (
    <div>
      <Layout>
        <Header className="header">
          <div className="logo">
            <Link to={"/"}>
              <img height={70} alt="logo" src={logo} />
            </Link>
          </div>

          {loanData?.logo_url && (
            <img height={65} alt="cu_logo" src={loanData?.logo_url} />
          )}

          {/* <Menu theme="light" mode="horizontal" items={headerMenu} /> */}
        </Header>
      </Layout>
      <br />
      <br />
      {loanData && loanAccess ? (
        <div>
          <LoanDetails data={loanData} guest={true} />
          <Link
            style={{ float: "right", marginRight: "2px" }}
            to={"/signup"}
          >
            <Button type="primary" style={{ float: "right" }}>
              CREATE TRANSACTION ➡️
            </Button>
          </Link>
        </div>
      ) : (
        <Space className="space" direction="vertical" style={{ width: "100%" }}>
          <Spin
            tip="An email has been sent to the buyer. Ask the buyer to click on the link to provide the access to loan details"
            size="large"
          >
            <div className="content" />
          </Spin>
        </Space>
      )}
    </div>
  );
}

export default GuestLoanDeatils;
