import { Descriptions, Modal, Upload } from 'antd'
import React, { useEffect, useState } from 'react'
import { getDocuments, getLoanDetails, getTransactionById } from '../../actions/Actions'
import { useDispatch, useSelector } from 'react-redux';
import Title from 'antd/es/typography/Title';
import LoanDetails from '../LoanDetails/LoanDetails';
import { formatAmount } from '../../util';
import "./TSModal.css";


const TSModal = ({ isModalVisible, handleOk, handleCancel, rowData }) => {
    const dispatch = useDispatch();

    const loanData = useSelector((state) => state.loanData);
    console.log("LOAN",loanData)
  const transaction = useSelector((state) => state.transaction);
  console.log(transaction)
 
  const documents = useSelector((state) => state.documents.urls);

    useEffect(() => {
        console.log(rowData)
     dispatch(getTransactionById(rowData.id));
     dispatch(getDocuments(rowData.id))
    }, [rowData])

    useEffect(() => {
      if(rowData && transaction.transaction){
     dispatch(getLoanDetails({dealer_id:rowData.dealer_id, cardId:rowData.loan_id,memberId:transaction?.transaction?.member_id}))
      }
    }, [rowData,transaction?.transaction, dispatch])
    

  return (
    <Modal
    className="custom-modal" 
        title="Transaction Summary"
        centered
        open={isModalVisible}
     onOk={handleOk}
      onCancel={handleCancel}
      footer= {null}
        width={1000}
      >

{transaction.transaction!==null && loanData!==false && documents!==null?
        (<>
              <LoanDetails data={loanData} isSummary={true} transaction={transaction?.transaction}/>
        <div style={{ backgroundColor: "#F3F3F3", padding: 15 }}>
        {/* <Descriptions title="Funding Details">
         
          <Descriptions.Item
            label="Total Financed Amount"
            className="description"
          >
            {formatAmount(transaction?.transaction?.amount)}
          </Descriptions.Item>
          <Descriptions.Item label="Approved Amount" className="description">
            {" "}
            {formatAmount(loanData?.preapproved_max_amt)}
          </Descriptions.Item>
          <Descriptions.Item label="Make" className="description">
            {transaction?.transaction?.make}
          </Descriptions.Item>
          <Descriptions.Item label="Model" className="description">
            {transaction?.transaction?.model}
          </Descriptions.Item>
          <Descriptions.Item label="Year" className="description">
            {transaction?.transaction?.year}{" "}
          </Descriptions.Item>
          {transaction?.transaction?.kbb == null ? (
            <Descriptions.Item label="MSRP" className="description">
              {formatAmount(transaction?.transaction?.msrp)}{" "}
            </Descriptions.Item>
          ) : (
            <Descriptions.Item
              label="Retail Book Value"
              className="description"
            >
              {formatAmount(transaction?.transaction?.kbb)}{" "}
            </Descriptions.Item>
          )}
          <Descriptions.Item label="VIN" className="description">
            {transaction?.transaction?.vin_number}{" "}
          </Descriptions.Item>
          <Descriptions.Item label="Mileage" className="description">
            {transaction?.transaction?.mileage}{" miles"}
          </Descriptions.Item> */}
          <Descriptions title="Funding Details">
          <Descriptions.Item
            label="Total Selling Price"
            className="description"
          >
            {formatAmount(transaction?.transaction?.amount)}
          </Descriptions.Item>
          <Descriptions.Item label="Max Loan Amount" className="description">
            {" "}
            {formatAmount(loanData?.preapproved_max_amt)}
          </Descriptions.Item>
          <Descriptions.Item label="Total Loan Amount" className="description">
            {formatAmount(
              transaction?.transaction?.amount -
                Number(transaction?.transaction?.down_payment_actual_amt)
            )}
          </Descriptions.Item>
          {transaction?.transaction?.kbb == null ? (
            <Descriptions.Item label="MSRP" className="description">
              {formatAmount(transaction?.transaction?.msrp)}{" "}
            </Descriptions.Item>
          ) : (
            <Descriptions.Item
              label="Retail Book Value"
              className="description"
            >
              {formatAmount(transaction?.transaction?.kbb)}{" "}
            </Descriptions.Item>
          )}
          <Descriptions.Item
            label="Down Payment Amount"
            className="description"
          >
            {formatAmount(
              Number(transaction?.transaction?.down_payment_actual_amt)
            )}
          </Descriptions.Item>
          {transaction?.transaction?.backend_dollars && (
            <Descriptions.Item label="Actual Backend Dollars" className="description">
              {formatAmount(transaction?.transaction?.backend_dollars)}
            </Descriptions.Item>
          )}
          <Descriptions.Item label="Mileage" className="description">
            {transaction?.transaction?.mileage}
            {" miles"}
          </Descriptions.Item>
          <Descriptions.Item label="Make" className="description">
            {transaction?.transaction?.make}
          </Descriptions.Item>
          <Descriptions.Item label="Model" className="description">
            {transaction?.transaction?.model}
          </Descriptions.Item>
          <Descriptions.Item label="Year" className="description">
            {transaction?.transaction?.year}{" "}
          </Descriptions.Item>

          <Descriptions.Item label="VIN" className="description">
            {transaction?.transaction?.vin_number}{" "}
          </Descriptions.Item>
        </Descriptions>
      </div>
      <br />

      {/* <LoanDetails data={loanData} isSummary={true} /> */}

      <div style={{ backgroundColor: "#F3F3F3", padding: 15, paddingTop: 5 }}>
        <Title level={5}>Insurance Card</Title>
        <Upload
          listType="picture-card"
          showUploadList={{
            showRemoveIcon: false,
          }}
          fileList={documents.insuranceCard}
        ></Upload>
      </div>
      <br />
      <div style={{ backgroundColor: "#F3F3F3", padding: 15, paddingTop: 5 }}>
        <Title level={5}>Driver License</Title>
        <Upload
          listType="picture-card"
          showUploadList={{
            showRemoveIcon: false,
          }}
          fileList={documents.driversLicense}
        ></Upload>
      </div>
      <br />
      <div style={{ backgroundColor: "#F3F3F3", padding: 15, paddingTop: 5 }}>
        <Title level={5}>Purchase Agreement</Title>
        <Upload
          listType="picture-card"
          showUploadList={{
            showRemoveIcon: false,
          }}
          fileList={documents.purchaseAgreement}
        ></Upload>
      </div>
      <br />
      <div style={{ backgroundColor: "#F3F3F3", padding: 15, paddingTop: 5 }}>
        <Title level={5}>Lien Reciept/Application For Title</Title>
        <Upload
          listType="picture-card"
          showUploadList={{
            showRemoveIcon: false,
          }}
          fileList={documents.lr}
        ></Upload>
      </div>
      <br />
      <div style={{ backgroundColor: "#F3F3F3", padding: 15, paddingTop: 5 }}>
        <Title level={5}>Others</Title>
        <Upload
          listType="picture-card"
          showUploadList={{
            showRemoveIcon: false,
          }}
          fileList={documents.other}
        ></Upload>
      </div></>):(<h5>Loading...</h5>)
}
      </Modal>
  )
}

export default TSModal