import React, { useEffect, useState } from "react";
import { Form, Button, InputNumber } from "antd";
import { requestEntry } from "../../actions/Actions";
import { useDispatch, useSelector } from "react-redux";

const RequestEntry = ({getCardId, }) => {
  const dispatch = useDispatch();
  const currentDealerhost = useSelector((state) => state.dealerHosts.current);
  const transaction = useSelector((state) => state.transaction);
  const [cardId, setcardId] = useState(null);
  const handleChange = (value) => {
    setcardId(value);
    getCardId(value)
  };

  const onFinish = (values) => {
    console.log(values)
    dispatch(requestEntry({ id: { cardId } , dealerhost_id: currentDealerhost.dealer_host_id, transactionId:transaction.currentTransactionId, dealer_id: currentDealerhost.dealer_id }));
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const sendRequest = () => {
    dispatch(requestEntry({ id: { cardId } , dealerhost_id: currentDealerhost.dealer_host_id, transactionId:transaction.currentTransactionId }));
  };

  const formatCreditCardNumber = (value) => {
    if (!value) return "";

    // Remove non-digit characters from the input
    const digitsOnly = value.toString().replace(/\D/g, "");

    // Split the credit card number into groups of 4 digits
    const groups = [];
    for (let i = 0; i < digitsOnly.length; i += 4) {
      groups.push(digitsOnly.substr(i, 4));
    }

    // Join the groups with a space between each group
    return groups.join("-");
  };

  const parseCreditCardNumber = (value) => {
    // Remove non-digit characters from the input
    return value.toString().replace(/\D/g, "").slice(0, 16);
  };

  return (
    <>
      <div style={{ backgroundColor: "#F3F3F3", padding: 15 }}>
        <Form  onFinish={onFinish}
        onFinishFailed={onFinishFailed}  layout="vertical">
        <Form.Item
       rules={[
              {
                required: true,
                message: " Credit Card ID is required",
              },
            ]} label="Credit Card ID" name="cardId">
          <InputNumber
            style={{ width: 300 }}
            name="cardId"
            onChange={handleChange}
            formatter={formatCreditCardNumber}
            parser={parseCreditCardNumber}
            placeholder="Enter 16-digit Credit Card ID"
            maxLength={19}
            value={cardId}
           
          />
        </Form.Item>

        <Button htmlType="submit" type="primary">
          Request Entry
        </Button>
        </Form>
      </div>
      <br />
    </>
  );
};

export default RequestEntry;
