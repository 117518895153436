import React, { useEffect } from "react";
import "./LoanData.css";
import { Button, Card, Form, Input, InputNumber } from "antd";
import { formatCreditCardNumber, parseCreditCardNumber } from "../../util";
import { useDispatch, useSelector } from "react-redux";
import { guestRequestEntry } from "../../actions/Actions";
import { useNavigate } from "react-router-dom";

function LoanData() {
  const emailRegex =  /^[^\s@]+@[^\s@]+\.[^\s@]+$/; ///^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const requestSent = useSelector((state) => state.requestEntry.requestSent);

  useEffect(() => {
    dispatch({
      type: "RESET_LOAN_DATA"
    });
    dispatch({
      type: "RESET_LOAN_ACCESS_REQUEST_SENT"
    });
  
   
  }, [])

  useEffect(() => {
    requestSent && navigate("/guest-loan-details");
  
  }, [requestSent])
  
  

  const onFinish =async (values) => {
    console.log(values);
    const x = dispatch(
      guestRequestEntry({
        loan_id: values.loan_id,
        dealer_nm: values.dealer_nm,
        email: values.email,
        name: values.f_name+" "+values.l_name,
      })
    );
    console.log(x)
    requestSent && navigate("/guest-loan-details");
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <div className="loandata">
      <Card
        bordered={false}
        style={{
          width: 300,
        }}
      >
        <div className="card-header">
          <img
            className="card-logo"
            src="https://loanifystoragedev.blob.core.windows.net/assest/FPay2.png"
            alt="Logo"
          />
          <div className="title">Dealers Portal</div>
        </div>
        <br />
        <br />
        <br />
        <Form
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
        >
          <div className="card-body">
            <Form.Item
              rules={[
                {
                  required: true,
                  message: " Credit Card ID is required",
                },
              ]}
              label="Credit Card ID"
              name="loan_id"
            >
              <InputNumber
                style={{ width: "100%" }}
                name="cardId"
                formatter={formatCreditCardNumber}
                parser={parseCreditCardNumber}
                placeholder="Enter 16-digit Credit Card ID"
                maxLength={19}
              />
            </Form.Item>

            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Dealer User First Name is required",
                },
              ]}
              label="Dealer User First Name"
              name="f_name"
            >
              <Input style={{ width: "100%" }} name="name" placeholder="Name" />
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Dealer User Last Name is required",
                },
              ]}
              label="Dealer User Last Name"
              name="l_name"
            >
              <Input style={{ width: "100%" }} name="name" placeholder="Name" />
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "A valid Dealer User Email is required",
                  pattern: emailRegex
                },
              ]}
              label="Dealer User Email"
              name="email"
            >
              <Input
                style={{ width: "100%" }}
                name="email"
                placeholder="Email"
              />
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Dealership Name is required",
                },
              ]}
              label="Dealership Name"
              name="dealer_nm"
            >
              <Input
                style={{ width: "100%" }}
                name="dealer_nm"
                placeholder="Dealership Name"
              />
            </Form.Item>
          </div>

          <div className="card-footer">
            {" "}
            <Button className="footer-button" htmlType="submit">
              REQUEST ACCESS{" "}
            </Button>
          </div>
        </Form>
      </Card>
    </div>
  );
}

export default LoanData;
