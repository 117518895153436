import React from "react";
import {
  Input,
  Select,
  Button,
  Typography,
  InputNumber,
  Form,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { invite } from "../../actions/Actions";

const Invite = () => {
  const getColorByStatus = (status) => {
    switch (status) {
      case "Accepted":
        return "darkgreen";

      case "Pending":
        return "yellow";

      case "Cancelled":
        return "gray";

      case "Expired":
        return "red";

      default:
        break;
    }
  };

  // const columns = [
  //   {
  //     title: "Email",
  //     dataIndex: "email",
  //     key: "email",
  //   },
  //   {
  //     title: "Role",
  //     dataIndex: "role",
  //     key: "role",
  //   },
  //   {
  //     title: "Date",
  //     dataIndex: "date",
  //     key: "date",
  //   },
  //   {
  //     title: "Status",
  //     key: "status",
  //     dataIndex: "status",
  //     render: (_, { status }) => (
  //       <>
  //         <Tag color={getColorByStatus(status)} key={status}>
  //           {status}
  //         </Tag>
  //       </>
  //     ),
  //   },
  //   {
  //     title: "Action",
  //     key: "action",
  //     render: (_, record) => (
  //       <Space size="middle">
  //         <Button type="primary" color="green">
  //           Cancel
  //         </Button>
  //       </Space>
  //     ),
  //   },
  // ];

  // const dataSource = [
  //   {
  //     key: "1",
  //     email: "robert.smith@gmail.com",
  //     role: "Sales Manager",
  //     date: "2023-05-08",
  //     status: "Accepted",
  //   },
  //   {
  //     key: "2",
  //     email: "victor.andrewson@gmail.com",
  //     role: "Sales Representative",
  //     date: "2023-05-08",
  //     status: "Accepted",
  //   },
  //   {
  //     key: "3",
  //     email: "Client Name",
  //     role: "Sales Representative",
  //     date: "2023-05-08",
  //     status: "Pending",
  //   },
  //   {
  //     key: "4",
  //     email: "Client Name",
  //     role: "Sales Representative",
  //     date: "2023-05-08",
  //     status: "Expired",
  //   },
  //   {
  //     key: "5",
  //     email: "Client Name",
  //     role: "Sales Representative",
  //     date: "2023-05-08",
  //     status: "Cancelled",
  //   },
  // ];

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const currentDealerhost = useSelector((state) => state.dealerHosts.current);
  const onFinish = (values) => {
    values.dealer_host_text_num = values.dealer_host_phn_num;
    values.dealer_host_login = values.dealer_host_email;
    values.dealer_id = currentDealerhost.dealer_id;
    values.market_nm = currentDealerhost.market_nm
    values.username = currentDealerhost.dealer_host_email
    console.log("Success:", values);
    dispatch(invite(values, form))
    //form.resetFields(); 
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const emailValidator = (_, value) => {
    // Regular expression to validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (value && !emailRegex.test(value)) {
      return Promise.reject("Invalid email format");
    }

    return Promise.resolve();
  };

  const phoneNumberValidator = (_, value) => {
    // Regular expression to validate phone number format
    const phoneRegex = /^[0-9]{10}$/;
    
    if (value && !phoneRegex.test(value)) {
      return Promise.reject('Invalid phone number format');
    }
    
    return Promise.resolve();
  };

  return (
    <>
      <div>
        {/* Heading */}
        <Typography.Title level={5} style={{ marginTop: 10 }}>
          Invite
        </Typography.Title>
      </div>
      {/* Form */}
      <Form
        layout="vertical"
        form={form}
        labelCol={{
          span: 16,
        }}
        wrapperCol={{
          span: 22,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Form.Item
            style={{ width: "100%" }}
            label="Full Name"
            name="dealer_host_nm"
            rules={[
              {
                required: true,
                message: "Please input your name!",
              },
            ]}
          >
            <Input placeholder="Please enter a valid name here" />
          </Form.Item>

          <Form.Item
            style={{ width: "100%" }}
            label="User Role"
            name="dealer_host_title"
            rules={[
              {
                required: true,
                message: "Required",
              },
            ]}
          >
            <Select
              placeholder="Please select role"
              style={{ width: "100%" }}
              options={[
                {
                  value: "General Sales Manager",
                  label: "General Sales Manager",
                },
                {
                  value: "Sales Manager",
                  label: "Sales Manager",
                },
                {
                  value: "Internet Director",
                  label: "Internet Director",
                },
                {
                  value: "Internet Manager",
                  label: "Internet Manager",
                },
                {
                  value: "Fleet Manager",
                  label: "Fleet Manager",
                },
                {
                  value: "Senior Sales",
                  label: "Senior Sales",
                },
                {
                  value: "Finance Director",
                  label: "Finance Director",
                },
                {
                  value: "Finance Manager",
                  label: "Finance Manager",
                },
              ]}
            />
          </Form.Item>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Form.Item
            style={{ width: "100%" }}
            label="User Email"
            name="dealer_host_email"
            rules={[
              { required: true, message: "Please enter your email" },
              { validator: emailValidator },
            ]}
          >
            <Input placeholder="Please enter a valid email address here" />
          </Form.Item>

          <Form.Item
            style={{ width: "100%" }}
            label="Cell Phone Number"
            name="dealer_host_phn_num"
            rules={[
              { required: true, message: 'Please enter your phone number' },
              { validator: phoneNumberValidator }
            ]}
          >
            <InputNumber
              style={{ width: "100%" }}
              controls={false}
              placeholder="Please enter a valid phone number here"
            />
          </Form.Item>
        </div>
        <Button
          style={{ marginTop: 25, float: "right" }}
          htmlType="submit"
          type="primary"
        >
          Invite
        </Button>
      </Form>

      {/* Data table */}
      {/* <Table
        style={{ marginTop: 30 }}
        columns={columns}
        dataSource={dataSource}
      /> */}
    </>
  );
};

export default Invite;
