import React from "react";
import { Button, Form, Input, InputNumber, Select } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { signupDealership } from "../../actions/Actions";
import logo from "../../images/FP_Logo.png";
import bgImage from "../../images/dealers-portal-bg.jpg";
import "./DealershipSignup.css";
import LoadingOverlay from "../LoadingOverlay/LoadingOverlay";
import { useEffect } from "react";

const DealershipSignup = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.loading);
  console.log("isLoading",isLoading)
  const [form] = Form.useForm();
  const phoneRegExp = /^\d{10}$/;
  const emailRegex =  /^[^\s@]+@[^\s@]+\.[^\s@]+$/; ///^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/;
  //const websiteRegex = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*)(\.[a-z]{2,6})$/;
  const websiteRegex = /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/;
  const onFinish = (values) => {
    console.log("Success:", values);
    //form.resetFields();
    values.dealer_id = null;
    values.dealer_lat = null;
    values.dealer_long = null;
    values.sales_phn_num = null;
    values.service_phn_num = null;
    values.parts_phn_num = null;
    values.dealer_address_zip_cd = null;
    values.hours_of_operation_line_1 = null;
    values.hours_of_operation_line_2 = null;
    values.hours_of_operation_line_3 = null;
    values.F_and_I_manager_role = null;
    values.F_and_I_manager_phn_num = null;
    values.F_and_I_text_num = null;
    values.zone_id = null;
    values.market_nm = "California";
    values.username = values.manager_email;
    values.dealer_lat = null;
    values.dealer_long = null;
    values.service_active_status_ind = "Y";
    values.manager_role = null;

    dispatch(signupDealership(values));
    dispatch({
      type: "SET_LOADING"
    });
  };
  useEffect(() => {
    dispatch({
      type: "RESET_LOADING"
    });
    dispatch({
      type: "RESET_LOAN_DATA"
    });
    dispatch({
      type: "RESET_LOAN_ACCESS_REQUEST_SENT"
    });
  }, []);
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const filterOption = (input, option) =>
  (option?.label ?? '').toLowerCase().startsWith(input.toLowerCase());

  return (
    <>
      <div className="outer-container">
      {isLoading && <LoadingOverlay message='Signing Up'/>}
        <div className="inner-container">
          <img
            alt="logo"
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              display: "block",
            }}
            src={logo}
            height="120 ev"
          />
          <h1
            style={{
              marginLeft: "35%",
            }}
          >
            Dealership Registration
          </h1>

          <Form
            form={form}
            layout="vertical"
            labelCol={{
              span: 16,
            }}
            wrapperCol={{
              span: 22,
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                alignItems:"flex-end"
              }}
            >
              <Form.Item
                label="Dealership Name"
                name="dealer_nm"
                rules={[
                  {
                    required: true,
                    message: "Please enter Dealer Name!",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Brand"
                name="brand"
                rules={[
                  {
                    required: true,
                    message: "Please enter your Brand!",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Manager Name"
                name="manager_nm"
                rules={[
                  {
                    required: true,
                    message: "Please enter Manager Name!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                alignItems:"flex-end"
              }}
            >
              <Form.Item
                label="Manager Cell Phone Number"
                name="manager_text_num"
                rules={[
                  {
                    required: true,
                    message: "Please enter a valid Phone Number!",
                    pattern: phoneRegExp,
                  },
                ]}
              >
                <Input addonBefore="+1" />
              </Form.Item>
              <Form.Item
                label="Manager Desk Phone Number"
                name="manager_phn_num"
                rules={[
                  {
                    required: true,
                    message: "Please enter valid Desk Phone Number!",
                    pattern: phoneRegExp,
                  },
                ]}
              >
                <Input addonBefore="+1" />
              </Form.Item>
              <Form.Item
                label="Manager Email"
                name="manager_email"
                rules={[
                  {
                    required: true,
                    message: "Please enter valid Email!",
                    pattern: emailRegex
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                alignItems:"flex-end"
              }}
            >
<Form.Item
                label="Dealership Website"
                name="dealer_website"
                rules={[
                  {
                    required: true,
                    message: "Please enter valid Website!",
                    pattern: websiteRegex
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item label="Dealership City" name="dealer_address_city_nm"  rules={[
                  {
                    required: true,
                    message: "Please enter Dealership City!",
                  },
                ]}>
                <Input />
              </Form.Item>
              <Form.Item
                label="Dealership State"
                name="dealer_address_state_cd"
                rules={[
                  {
                    required: true,
                    message: "Please select Dealership State!",
                  },
                ]}
              >
                <Select
                showSearch
                filterOption={filterOption}
                options={[
                  { label: 'Alabama', value: 'AL' },
                  { label: 'Alaska', value: 'AK' },
                  { label: 'Arizona', value: 'AZ' },
                  { label: 'Arkansas', value: 'AR' },
                  { label: 'California', value: 'CA' },
                  { label: 'Colorado', value: 'CO' },
                  { label: 'Connecticut', value: 'CT' },
                  { label: 'Delaware', value: 'DE' },
                  { label: 'Florida', value: 'FL' },
                  { label: 'Georgia', value: 'GA' },
                  { label: 'Hawaii', value: 'HI' },
                  { label: 'Idaho', value: 'ID' },
                  { label: 'Illinois', value: 'IL' },
                  { label: 'Indiana', value: 'IN' },
                  { label: 'Iowa', value: 'IA' },
                  { label: 'Kansas', value: 'KS' },
                  { label: 'Kentucky', value: 'KY' },
                  { label: 'Louisiana', value: 'LA' },
                  { label: 'Maine', value: 'ME' },
                  { label: 'Maryland', value: 'MD' },
                  { label: 'Massachusetts', value: 'MA' },
                  { label: 'Michigan', value: 'MI' },
                  { label: 'Minnesota', value: 'MN' },
                  { label: 'Mississippi', value: 'MS' },
                  { label: 'Missouri', value: 'MO' },
                  { label: 'Montana', value: 'MT' },
                  { label: 'Nebraska', value: 'NE' },
                  { label: 'Nevada', value: 'NV' },
                  { label: 'New Hampshire', value: 'NH' },
                  { label: 'New Jersey', value: 'NJ' },
                  { label: 'New Mexico', value: 'NM' },
                  { label: 'New York', value: 'NY' },
                  { label: 'North Carolina', value: 'NC' },
                  { label: 'North Dakota', value: 'ND' },
                  { label: 'Ohio', value: 'OH' },
                  { label: 'Oklahoma', value: 'OK' },
                  { label: 'Oregon', value: 'OR' },
                  { label: 'Pennsylvania', value: 'PA' },
                  { label: 'Rhode Island', value: 'RI' },
                  { label: 'South Carolina', value: 'SC' },
                  { label: 'South Dakota', value: 'SD' },
                  { label: 'Tennessee', value: 'TN' },
                  { label: 'Texas', value: 'TX' },
                  { label: 'Utah', value: 'UT' },
                  { label: 'Vermont', value: 'VT' },
                  { label: 'Virginia', value: 'VA' },
                  { label: 'Washington', value: 'WA' },
                  { label: 'West Virginia', value: 'WV' },
                  { label: 'Wisconsin', value: 'WI' },
                  { label: 'Wyoming', value: 'WY' },
                ]}
                placeholder="Select a State"
                />
              </Form.Item>
              
            </div>
            <div
              style={{
                display: "flex",
                alignItems:"flex-end"
                
              }}
            >
              <Form.Item
              style={{width:"50%"}}
                label="Dealership Main Phone Number"
                name="main_phn_num"
                 rules={[
                  {
                    required: true,
                    message: "Please enter valid Phone Number!",
                    pattern: phoneRegExp,
                  },
                ]}
              >
                <Input addonBefore="+1" />
              </Form.Item>
              <Form.Item
 style={{width:"100%"}}
                label="Dealership Address"
                name="dealer_address_line_1"
                rules={[
                  {
                    required: true,
                    message: "Please enter Dealership Address!",
                  },
                ]}
              >
                <Input style={{width:"770px"}}/>
              </Form.Item>
              {/* <Form.Item label="Dealership City" name="dealer_address_city_nm">
                <Input />
              </Form.Item>
              <Form.Item
                label="Dealership State"
                name="dealer_address_state_cd"
              >
                <Input />
              </Form.Item> */}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                alignItems:"flex-end"
              }}
            >
              <Form.Item
                label="F & I Director Name"
                name="F_and_I_manager_nm"
                rules={[
                  {
                    required: true,
                    message: "Please enter F and I Director Name!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
              label="F & I Director Email"
              name="F_and_I_email"
              rules={[
                {
                  required: true,
                  message: "Please enter valid F and I Director Email!",
                  pattern: emailRegex
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item></Form.Item>
            </div>
            

            <Form.Item
              wrapperCol={{
                offset: 11,
                span: 16,
              }}
            >
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
};

export default DealershipSignup;
