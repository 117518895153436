import React from "react";
import { Collapse, Descriptions, Select } from "antd";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import "./LoanDetails.css";
import TextArea from "antd/es/input/TextArea";
import { formatAmount } from "../../util";
import { useEffect } from "react";
import { getintrestRates } from "../../actions/Actions";
import { useState } from "react";

const LoanDetails = ({ data, isSummary, rateActual, ltvActual, termActual, transaction ,guest}) => {
  const dispatch = useDispatch();
  const intrestRates = useSelector((state) => state.requestEntry.intrestRates);
  const [rate, setRate] = useState(false);
  const [ltv, setLTV] = useState(false);
  console.log("LD TRANSACTION:",transaction)



  useEffect(() => {
    dispatch(getintrestRates({lender_id:data.lender_id, credit_score:data.fico_score}))
  }, [])
  

  const formatCreditCardNumber = (value) => {
    if (!value) return "";

    // Remove non-digit characters from the input
    const digitsOnly = value.toString().replace(/\D/g, "");

    // Split the credit card number into groups of 4 digits
    const groups = [];
    for (let i = 0; i < digitsOnly.length; i += 4) {
      groups.push(digitsOnly.substr(i, 4));
    }

    // Join the groups with a space between each group
    return groups.join("-");
  };

  // const formatAmount = (value) => {
  //   return `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  // };


  const customTitle = (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        fontWeight: "normal",
      }}
    >
      <div>
        {data.lenderNm} Member:{" "}
        <b>
          {data.member_first_name} {data.member_last_name}
        </b>{" "}
        Pre-Approved Details.
      </div>
      {/* <div style={{fontSize:"Large"}}> 
    Approved Amount:{formatAmount(data.preapproved_max_amt)}
     </div> */}
      <div>
        Loan Expiration Date:{" "}
        <b>{new moment(data.preapproved_expire_date).format("LL")}</b>
      </div>
    </div>
  );

  const handleDropdownChange = (value) => {
    console.log("Selected option:", value);
    if(guest){
      intrestRates.forEach(function (arrayItem) {
        if(value >= arrayItem.min_term  && value<= arrayItem.max_term){
          setRate(arrayItem.interest_rate);
          //rateActual(arrayItem.interest_rate);
          setLTV(arrayItem.ltv)
          //ltvActual(arrayItem.ltv)
          //termActual(value)
        }
    });
    }
else{
    intrestRates.forEach(function (arrayItem) {
      if(value >= arrayItem.min_term  && value<= arrayItem.max_term){
        setRate(arrayItem.interest_rate);
        rateActual(arrayItem.interest_rate);
        setLTV(arrayItem.ltv)
        ltvActual(arrayItem.ltv)
        termActual(value)
      }
  });
}
  };



  // const downPayment = () => {
  //   console.log("insode DP");
  //   let dp = (data.down_payment / 100) * data.preapproved_max_amt;
  //   console.log("DOWNPAYMENT", dp);
  //   return formatAmount(dp);
  // };

  return (
    <>
      <Descriptions
        style={{ backgroundColor: "#F3F3F3", padding: 15 }}
        title={customTitle}
      >
        <Descriptions.Item label="Card ID" className="description">
          {formatCreditCardNumber(data.cardId)}
        </Descriptions.Item>
        <Descriptions.Item
          label="Approved Amount"
          className="amount description"
        >
          <span style={{color:"#33CB2B"}}>{formatAmount(data.preapproved_max_amt)}</span>
        </Descriptions.Item>
        <Descriptions.Item label="Minimum Down Payment" className="description">
          {data.down_payment}%
        </Descriptions.Item>

        <Descriptions.Item label="Term" className="description" >
        {
          isSummary?(transaction?.term? transaction?.term+" months":data.term+" months"):(
            <Select
          style={{width:"auto", marginTop:"-5px"}}
            defaultValue={{
              value: transaction?.term? transaction?.term:data.term+"",
                label: transaction?.term? transaction?.term+" months":data.term+" months",
              
            }}//{dv()} //{data.term!==null && data.term!==undefined ?data.term+ " months" :"84 months" } 
            onChange={handleDropdownChange}
            options={[
              {
                value: 24,
                label: "24 months",
              },
              {
                value: 36,
                label: "36 months",
              },
              {
                value: 48,
                label: "48 months",
              },
              {
                value: 60,
                label: "60 months",
              },
              {
                value: 72,
                label: "72 months",
              },
              {
                value: 84,
                label: "84 months",
              },
            ]}
          />

          )
        }
          
          {" "}
        </Descriptions.Item>

        <Descriptions.Item label="Interest Rate" className="description">
          {/* {rate?rate:data.preapproved_pct}% APR */}
          {rate? rate :(transaction?.interest_rates?transaction?.interest_rates:data.preapproved_pct)}% APR
        </Descriptions.Item>
        <Descriptions.Item label="Loan To Value (LTV)" className="description">
          {/* {ltv?ltv:data.ltv}% */}
          {ltv?ltv:(transaction?.ltv? transaction?.ltv : data.ltv)}%
        </Descriptions.Item>

        {/* 
        NOT DISPLAYING CREDIT SCORE
        <Descriptions.Item label="Credit Score" className="description">
            {data.fico_score}
            </Descriptions.Item> */}

        {/* <Descriptions.Item label="Lowest Intrest Rate">
        {data.preapproved_pct}% APR
        </Descriptions.Item> */}



        {/* <Descriptions.Item label="Loan Expiration Date">
          {new moment(data.preapproved_expire_date).format('LL')}
        </Descriptions.Item> */}
        {data.stipulations ?
        
           (<></> ) : (
            <Descriptions.Item label="Stipulations" className="description">
            None
            </Descriptions.Item>
          )}
        
        {data.backend_dollars > 0 ? (
          <Descriptions.Item
            label="Backend Dollars Available"
            className="description"
          >
            {formatAmount(data.backend_dollars)}
          </Descriptions.Item>
        ) : (
          <Descriptions.Item className="description">
            {data.lenderNm} welcomes Back End Products that fit into the
            APPROVED AMOUNT/ LTV.
          </Descriptions.Item>
        )}
        
        {/* <Descriptions.Item label="Down Payment Amount" className="description">
          {downPayment()}
        </Descriptions.Item> */}
      </Descriptions>
      <br />
      {data.stipulations ? (<> <Descriptions
        style={{ backgroundColor: "#F3F3F3", padding: 15 }}
      >
        <Descriptions.Item label="Stipulations" className="description">  <TextArea style={{backgroundColor: "#F3F3F3", border:"none",minHeight: '100px',}}  autoSize={{ minRows: 5, maxRows: 100 }} className="description" value={data.stipulations} readOnly={true} /></Descriptions.Item>
        
      </Descriptions>
      <br/></> ) : (
            <></>
          )}
     
    </>
  );
};

export default LoanDetails;
