// LoadingOverlay.js
import React from 'react';
import './LoadingOverlay.css'; // Apply CSS styles for overlay
import Lottie from 'react-lottie';
import animationData from '../../images/car-animation.json'

const LoadingOverlay = ({message}) => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        renderer: 'svg'
    }
  return (
    <div className="loading-overlay">
      {/* <div className="loading-spinner"></div> */}
      <Lottie
      options={defaultOptions}
                height={500}
                width={500}
               />
      <h3>{message?message:'Loading...'}</h3>
    </div>
  );
};

export default LoadingOverlay;
