import { Button, Descriptions, Steps, Tag, Upload } from "antd";
import Title from "antd/es/typography/Title";
import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  CreditCardOutlined,
  FileDoneOutlined,
  DollarCircleOutlined,
  CloudUploadOutlined,
  SolutionOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import LoanDetails from "../LoanDetails/LoanDetails";
import { getDocuments, sendSubmitRequest } from "../../actions/Actions";
import { formatAmount } from "../../util";
import "./TransactionSummary.css";


const TransactionSummary = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loanData = useSelector((state) => state.loanData);
  const transaction = useSelector((state) => state.transaction);
  console.log(transaction.transaction);
  const documents = useSelector((state) => state.documents.urls);

  let steps = [
    {
      title: (
        <span style={{ color: "#33CB2B", fontWeight: "bold" }}>Loan Details</span>
      ),
      status: "finish",
      icon: <CreditCardOutlined style={{ color: "#33CB2B" }} />,
    },
    {
      title: (
        <span style={{ color: "#33CB2B", fontWeight: "bold" }}>
          Funding Details
        </span>
      ),
      status: "finish",
      icon: <DollarCircleOutlined style={{ color: "#33CB2B" }} />,
    },
    {
      title: (
        <span style={{ color: "#33CB2B", fontWeight: "bold" }}>
          Upload Documents
        </span>
      ),
      status: "finish",
      icon: <CloudUploadOutlined style={{ color: "#33CB2B" }} />,
    },
    {
      title: (
        <span style={{ color: "#1677ff", fontWeight: "bold" }}>
          Transaction Summary
        </span>
      ),
      status: "process",
      icon: <SolutionOutlined />,
    },
    {
      title: "Buyers Approval",
      status: "wait",
      icon: <FileDoneOutlined />,
    },
  ];

  const handleClick = () => {
    dispatch(sendSubmitRequest({ id: transaction.currentTransactionId }));
    navigate("/confirmation");
  };

  useEffect(() => {
    if (transaction.currentTransactionId)
      dispatch(getDocuments(transaction.currentTransactionId));
  }, []);

  return (
    <>
      <Steps items={steps} />
      <LoanDetails
        data={loanData}
        isSummary={true}
        transaction={transaction.transaction}
      />
      <div style={{ backgroundColor: "#F3F3F3", padding: 15 }}>
        <Descriptions title="Funding Details">
          <Descriptions.Item
            label="Total Selling Price"
            className="description"
          >
            {formatAmount(transaction.transaction.amount)}
          </Descriptions.Item>
          <Descriptions.Item label="Max Loan Amount" className="description">
            {" "}
            {formatAmount(loanData.preapproved_max_amt)}
          </Descriptions.Item>
          <Descriptions.Item label="Total Loan Amount" className="description">
            {formatAmount(
              transaction.transaction.amount -
                Number(transaction.transaction.down_payment_actual_amt)
            )}
          </Descriptions.Item>
          {transaction.transaction.kbb == null ? (
            <Descriptions.Item label="MSRP" className="description">
              {formatAmount(transaction.transaction.msrp)}{" "}
            </Descriptions.Item>
          ) : (
            <Descriptions.Item
              label="Retail Book Value"
              className="description"
            >
              {formatAmount(transaction.transaction.kbb)}{" "}
            </Descriptions.Item>
          )}
          <Descriptions.Item
            label="Down Payment Amount"
            className="description"
          >
            {formatAmount(
              Number(transaction.transaction.down_payment_actual_amt)
            )}
          </Descriptions.Item>
          {transaction.transaction.backend_dollars && (
            <Descriptions.Item label="Actual Backend Dollars" className="description">
              {formatAmount(transaction.transaction.backend_dollars)}
            </Descriptions.Item>
          )}
          <Descriptions.Item label="Mileage" className="description">
            {transaction.transaction.mileage}
            {" miles"}
          </Descriptions.Item>
          <Descriptions.Item label="Make" className="description">
            {transaction.transaction.make}
          </Descriptions.Item>
          <Descriptions.Item label="Model" className="description">
            {transaction.transaction.model}
          </Descriptions.Item>
          <Descriptions.Item label="Year" className="description">
            {transaction.transaction.year}{" "}
          </Descriptions.Item>

          <Descriptions.Item label="VIN" className="description">
            {transaction.transaction.vin_number}{" "}
          </Descriptions.Item>

          {/* <Descriptions.Item label="Term" className="description">
            {transaction.transaction.term}
          </Descriptions.Item>
          <Descriptions.Item label="LTV" className="description">
            {transaction.transaction.ltv} {" %"}
          </Descriptions.Item>
          <Descriptions.Item label="Intrest Rate" className="description">
            {transaction.transaction.interest_rates}
          </Descriptions.Item> */}
        </Descriptions>
      </div>
      <br />

      <div style={{ backgroundColor: "#F3F3F3", padding: 15, paddingTop: 5 }}>
        <Title level={5}>Insurance Card</Title>
        <Upload
          listType="picture-card"
          showUploadList={{
            showRemoveIcon: false,
          }}
          fileList={documents.insuranceCard}
        ></Upload>
      </div>
      <br />
      <div style={{ backgroundColor: "#F3F3F3", padding: 15, paddingTop: 5 }}>
        <Title level={5}>Driver License</Title>
        <Upload
          listType="picture-card"
          showUploadList={{
            showRemoveIcon: false,
          }}
          fileList={documents.driversLicense}
        ></Upload>
      </div>
      <br />
      <div style={{ backgroundColor: "#F3F3F3", padding: 15, paddingTop: 5 }}>
        <Title level={5}>Purchase Agreement</Title>
        <Upload
          listType="picture-card"
          showUploadList={{
            showRemoveIcon: false,
          }}
          fileList={documents.purchaseAgreement}
        ></Upload>
      </div>
      <br />
      <div style={{ backgroundColor: "#F3F3F3", padding: 15, paddingTop: 5 }}>
        <Title level={5}>Lien Reciept/Application For Title</Title>
        <Upload
          listType="picture-card"
          showUploadList={{
            showRemoveIcon: false,
          }}
          fileList={documents.lr}
        ></Upload>
      </div>
      <br />
      <div style={{ backgroundColor: "#F3F3F3", padding: 15, paddingTop: 5 }}>
        <Title level={5}>Others</Title>
        <Upload
          listType="picture-card"
          showUploadList={{
            showRemoveIcon: false,
          }}
          fileList={documents.other}
        ></Upload>
      </div>

      <br />
      <Button type="primary" onClick={handleClick} style={{ float: "right" }}>
        GET BUYERS APPROVAL ➡️
      </Button>

      <Link
        style={{ float: "right", marginRight: "2px" }}
        to={"/upload-documents"}
      >
        <Button>Back</Button>
      </Link>
    </>
  );
};

export default TransactionSummary;
