import {
  Button,
  Descriptions,
  Select,
  Form,
  Input,
  InputNumber,
  Tag,
  Checkbox,
  Typography,
  Radio,
} from "antd";
import {
  CreditCardOutlined,
  FileDoneOutlined,
  DollarCircleOutlined,
  CloudUploadOutlined,
  SolutionOutlined,
} from "@ant-design/icons";
import { Steps } from "antd";
import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formatAmount } from "../../util";
import {
  getTransactionById,
  getVinData,
  updateTransaction,
} from "../../actions/Actions";
import { isString } from "antd/es/button";
import "./FinanceAmount.css";
import { months } from "moment";

//let years = [2019,2020,2021,2022,2023]
let years = [];
for (let i = 1980; i < 2023; i++) {
  years.push({
    value: i,
    label: i,
  });
}

const FinanceAmount = () => {
  const [checked, setChecked] = useState(false);
  const [checkedUsed, setCheckedUsed] = useState(false);
  const [downPaymentAmount, setDownPaymentAmount] = useState(0);
  const [downPaymentActualPer, setDownPaymentActualPer] = useState(0);
  const [tla, setTla] = useState(0);
  const [maxLoanApproval, setMaxLoanApproval] = useState(0);
  const [rule1, setRule1] = useState(false);
  const [rule2, setRule2] = useState(false);
  const [rule3, setRule3] = useState(false);
  const [eligible, setEligible] = useState(null);
  const loanData = useSelector((state) => state.loanData);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const transaction = useSelector((state) => state.transaction);
  const [term, setTerm] = useState(transaction?.transaction?.term);
  const [ltv, setLtv] = useState(transaction?.transaction?.ltv);
  const [interestRate, setInterestRate] = useState(transaction?.transaction?.interest_rates);
  const currentDealerhost = useSelector((state) => state.dealerHosts.current);
  const intrestRatesMatrix = useSelector((state) => state.requestEntry.intrestRates);
  const [form] = Form.useForm();

  let steps = [
    {
      title: <span style={{color:"#33CB2B", fontWeight:"bold"}}>Loan Details</span>,
      status: "finish",
      icon: <CreditCardOutlined style={{color:"#33CB2B"}}/>,
    },
    {
      title: <span style={{color:"#1677ff", fontWeight:"bold"}}>Funding Details</span>,
      status: "process",
      icon: <DollarCircleOutlined />,
    },
    {
      title: "Upload Documents",
      status: "wait",
      icon: <CloudUploadOutlined />,
    },
    {
      title: "Transaction Summary",
      status: "wait",
      icon: <SolutionOutlined />,
    },
    {
      title: "Buyers Approval",
      status: "wait",
      icon: <FileDoneOutlined />,
    },
  ];

  useEffect(() => {
   let dp = (loanData.down_payment / 100) * Number(form.getFieldValue("amount"));
   console.log("MIN DOWNPAYMENT", dp);
   form.setFieldsValue({
     down_payment_amount: isNaN(dp)?0:dp.toFixed(2),
   })
  setDownPaymentAmount(isNaN(dp)?0:dp.toFixed(2));
  setTla(isNaN(dp)?transaction?.transaction?.amount:transaction?.transaction?.amount - downPaymentAmount);
 
    updateDownpaymentPer()
  }, [transaction.transaction, loanData,tla,maxLoanApproval,downPaymentAmount,interestRate,term,form]);

  useEffect(() => {
    dispatch(getTransactionById(transaction?.currentTransactionId));
  }, [transaction.currentTransactionId])
  
  useEffect(() => {
    console.log("USEEFFECT CALLED TRANSACTION UPDATED")
    if(transaction?.transaction?.msrp == null && transaction?.transaction?.kbb == null){
      setChecked(true);
     }
     else{
      if (transaction?.transaction?.msrp == null){
        setChecked(false);
       }
       else{
        setChecked(true);
       }
     }
    setTerm(transaction?.transaction?.term)
    form.setFieldsValue({
      term:transaction?.transaction?.term
    })
    setInterestRate(transaction?.transaction?.interest_rates)
    setLtv(transaction?.transaction?.ltv)
  }, [transaction.transaction])
  
 

  console.log(transaction);

  const VINValidator = (_, value) => {
    console.log(value);
    
    // Regular expression to validate email format
    const regex = /^[A-HJ-NPR-Z0-9]{17}$/;
    if(value==null){
      //case already handled in required criteria
      return Promise.resolve();
    }
    else if (value && !regex.test(value)) {
      return Promise.reject("Invalid VIN Format");
    }
    form.setFieldsValue({
      vin_number: value.trim(),
    });
    handleVinChange(value);
    return Promise.resolve();
  };

  // const FinanceAmountValidator = (_, value) => {
  //   console.log(value);
  //   console.log(loanData.preapproved_max_amt);
  //   if (value > loanData.preapproved_max_amt){
  //     setEligible(false);
  //     console.log(value+">"+loanData.preapproved_max_amt)
  //     return Promise.reject(
  //       "Total Finance Amount (Cannot exceed "+ formatAmount(loanData.preapproved_max_amt)+")"
  //     );
  //   }
  //   else{
  //     setEligible(true);
  //     return Promise.resolve();
  //   }

  //   return Promise.resolve();
  // };
  // const MaxLoanAmountRule = (_, value) => {
  //   console.log("INSIDES MAXLOAN AMOUNT RULE")
  //   value = form.getFieldValue('tla') 
  //   if (value > loanData.preapproved_max_amt){
  //     setEligible(false);
  //     console.log(value+">"+loanData.preapproved_max_amt)
  //     console.log(form.getFieldError('down_payment_actual_amt'));

  //     form.setFields([
  //       {
  //         name: 'down_payment_actual_amt', // Name of the field to set error for
  //         errors: [`Total Loan Amount Cannot exceed Max Advance(CU Max Loan Amount)`], // Error message(s)
  //       },
  //     ]);
  //     // return Promise.reject(
  //     //   "Total Finance Amount (Cannot exceed "+ formatAmount(loanData.preapproved_max_amt)+")"
  //     // );
  //     //return Promise.resolve();
  //   }
  //   else{
  //     setEligible(true);
  //     return Promise.resolve();
  //   }

  // };
  const LTVRule = (_, value) => {
    let msrp = form.getFieldValue("msrp");
    let kbb = form.getFieldValue("kbb");
    if(checkedUsed){
      value = kbb;
    }
    else{
      value = msrp
    }

    let mla = (loanData.ltv * value/100)
    mla = isNaN(mla)?0:mla;
    console.log("maxLoanApproval",mla)
    setMaxLoanApproval(mla)

    if (Number(form.getFieldValue("down_payment_actual_amt")) < Number(form.getFieldValue("down_payment_amount"))){
      console.log( typeof form.getFieldValue("down_payment_actual_amt")  )
      console.log(form.getFieldValue("down_payment_actual_amt") +"<"+ form.getFieldValue("down_payment_amount"))
      console.log(form.getFieldValue("down_payment_actual_amt") +"<"+ form.getFieldValue("down_payment_amount"))
      setEligible(false);
      setRule1(false)
    }
    else{
      setRule1(true);
      
    }
    if (Number(tla)  > Number(loanData.preapproved_max_amt)){
      setEligible(false);
      console.log(tla+">"+loanData.preapproved_max_amt)
      setRule2(false)
    }
    else{
      setRule2(true);
     

      }
    if(rule1&&rule2){
    setEligible(rule1&&rule2)
    return Promise.resolve();
    }

// RULE 3 Not Required
  // if (maxLoanApproval < Number(form.getFieldValue("tla"))){
  //   setEligible(false);
  //     setRule3(false)

  //   }
  //   else{
  //     setRule3(true);
  //     setEligible(true);
  //     return Promise.resolve();
  //   }

  };


  const updateDownpaymentPer =() =>{
   
    let dpPercentage = (form.getFieldValue("down_payment_actual_amt") * 100) / form.getFieldValue("amount");
    console.log("DOWNPAYMENTAMT Percentage", dpPercentage);
    setTla(isNaN(form.getFieldValue("amount") - form.getFieldValue("down_payment_actual_amt"))?0:form.getFieldValue("amount") - form.getFieldValue("down_payment_actual_amt"))
    form.setFieldsValue({
      down_payment_actual_per: !isNaN(dpPercentage.toFixed(2)) && isFinite(dpPercentage.toFixed(2))? Number(dpPercentage.toFixed(2)): 0,
    })
    setDownPaymentActualPer( !isNaN(dpPercentage.toFixed(2)) && isFinite(dpPercentage.toFixed(2))? Number(dpPercentage.toFixed(2)): 0)
    LTVRule();
  } 
  

  const onChange = (e) => {
    console.log("NEW = ", e.target.value);
    setChecked(e.target.value);
    form.setFieldsValue({
      mileage:null,
    });

    if(checked){
      form.setFieldsValue({
        kbb: null,
      });
    }
    else{
      form.setFieldsValue({
        msrp: null,
      });
    }
  
  };
  // const onChangeUsed = (e) => {
  //   console.log("USED = ", e.target.checked);
  //   setCheckedUsed(e.target.checked);
  //   setChecked(!e.target.checked);
  //   form.setFieldsValue({
  //     mileage: checkedUsed ? 0 : null,
  //   });
  //   if(checked){
  //     form.setFieldsValue({
  //       kbb: null,
  //     });
  //   }
  //   else{
  //     form.setFieldsValue({
  //       msrp: null,
  //     });
  //   }
  // };

  const handleDropdownChange =(value)=>{
    intrestRatesMatrix.forEach(function (arrayItem) {
      if(value >= arrayItem.min_term  && value<= arrayItem.max_term){
       setInterestRate( arrayItem.interest_rate)
       setTerm(value);
       setLtv(arrayItem.ltv);
      }
  });
  }

  const onFinish = (values) => {
    console.log("Success:", values);
    form.resetFields();
    values.transactionId = transaction.currentTransactionId;
    values.dealer_id = currentDealerhost.dealer_id;
    values.cardId = loanData.cardId;
    values.member_id = transaction.transaction.member_id;
    if (values.kbb === undefined) {
      values.kbb = null;
    }
    if (values.msrp === undefined) {
      values.msrp = null;
    }
    if (values.backend_dollars === undefined) {
      values.backend_dollars = null;
    }
    if (values.term === undefined) {
      values.term = null;
    }
    if (values.ltv === undefined) {
      values.ltv = null;
    }
    values.tla = tla;
    values.interest_rates = interestRate;
    values.term = term;
    values.ltv = ltv;
    dispatch(updateTransaction(values));
    navigate("/upload-documents");
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleVinChange = async (vin) => {
    console.log(vin);
    if (isString(vin) && vin !== null && vin !== "") {
      let res = await getVinData(vin, transaction?.transaction);
      if (res) {
        form.setFieldsValue({
          make: res.Make,
          model: res.Model,
          year: res.ModelYear,
        });
      }
    }
  };


  const MileageRule = (_,value) =>{
    let mileage = form.getFieldValue('mileage')
    if (mileage>=600 && checked) {
      return Promise.reject("Select Used Vehicle if Mileage is above 599 miles");
    }
    return Promise.resolve();
  }

  return (
    <>
      <Steps items={steps} />
      {/* {!eligible ? ( */}
      <>
      {transaction?.transaction &&
        <Form
          initialValues={transaction?.transaction}
          layout="vertical"
          form={form}
          labelCol={{
            span: 16,
          }}
          wrapperCol={{
            span: 22,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <div style={{ backgroundColor: "#F3F3F3", padding: 15 }}>
            {/* {eligible !== null ? (
              eligible ? (
                <Tag style={{ float: "right",  position:"absolute", right:"0", marginRight:"75px",fontSize:"larger" }} color="geekblue">
                  Eligible
                </Tag>
              ) : (
                <Tag style={{ float: "right", position:"absolute", right:"0", marginRight:"75px",fontSize:"larger" }} color="volcano">
                  Ineligible
                </Tag>
              )
            ) : (
              <></>
            )} */}

<div
              style={{
                display: "flex",
                flexDirection: "column",
                fontWeight:"bold",
              }}
            >
              <Typography.Title level={3}>Eligibility Criteria:</Typography.Title>
              <Typography.Text type={rule1?"success":"danger"} >Down Payment cannot be less than Minimum Down Payment Amount ({formatAmount(form.getFieldValue("down_payment_amount"))}) {rule1?"✅":"❌"}</Typography.Text>
              <Typography.Text type={rule2?"success":"danger"}>Total Loan Amount cannot exceed Max Advance ({formatAmount(loanData.preapproved_max_amt)})  {rule2?"✅":"❌"}</Typography.Text>
              </div>

              <br/>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Form.Item
                style={{ width: "100%" , fontWeight:"bold"}}
                label={'Total Selling Price'}
                tooltip={'(Price of the Vehicle + Tax + Fees + Backend Products +/- Trade Equity)'}
                name="amount"
                rules={[
                  {
                    required: true,
                    message: "Total Selling Price is required",
                  },
                  // { validator: FinanceAmountValidator },
                ]}
              >
                <InputNumber
                onChange={()=>{updateDownpaymentPer();}}
                  style={{ width: "100%" }}
                  formatter={(value) =>
                    `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                />
              </Form.Item>

              <Form.Item style={{ width: "100%", fontWeight:"bold" }} label="Max Loan Amount">
                <Descriptions.Item label="Max Loan Amount">
                  {formatAmount(loanData.preapproved_max_amt)}
                </Descriptions.Item>
              </Form.Item>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Form.Item
              
                style={{ width: "100%" , fontWeight:"bold"}}
                label="Term"
                name="term"
              >
                
                <Select
          style={{width:"auto", marginTop:"-5px"}}
            defaultValue={{
              value: term,
              label:  term+" months",
            }}
            onChange={handleDropdownChange}
            options={[
              {
                value: 24,
                label: "24 months",
              },
              {
                value: 36,
                label: "36 months",
              },
              {
                value: 48,
                label: "48 months",
              },
              {
                value: 60,
                label: "60 months",
              },
              {
                value: 72,
                label: "72 months",
              },
              {
                value: 84,
                label: "84 months",
              },
            ]}
          />
              </Form.Item>

              <Form.Item
                style={{ width: "100%" , fontWeight:"bold"}}
                label="Interest Rates"
                name="interest_rates"
              >
               
                
      <span className="ant-form-text"><b>{interestRate}%</b></span>
              </Form.Item>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Form.Item
              
                style={{ width: "100%" , fontWeight:"bold"}}
                label="Minimum Down Payment Percentage"
                name="down_payment"
              >
                {/* <InputNumber
                readOnly={true}
                  controls={false}
                  precision={2}
                  addonAfter="%"
                  style={{
                    width: "100%",
                  }}
                /> */}
                      <span className="ant-form-text"><b>{loanData.down_payment}%</b></span>
              </Form.Item>

              <Form.Item
                style={{ width: "100%" , fontWeight:"bold"}}
                label="Minimum Down Payment Amount"
                name="down_payment_amount"
              >
                 {/* <InputNumber
                  readOnly
                  precision={2}
                  // style={{ width: "100%", border: 'none', boxShadow: 'none', color: 'inherit', padding: 0, backgroundColor:"#F3F3F3", fontWeight:"bold"}}
                  formatter={(value) =>
                    `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  placeholder="Down Payment Amount"
                /> */}
                
      <span className="ant-form-text"><b>{formatAmount(downPaymentAmount)}</b></span>
              </Form.Item>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Form.Item
              
                style={{ width: "100%" , fontWeight:"bold"}}
                label="Actual Down Payment Percentage"
                name="down_payment_actual_per"
                 rules={[
                  {
                    required: true,
                    message: "Down Payment Percentage is Required",
                  },
                ]}
              >
                 <span className="ant-form-text"><b>{downPaymentActualPer}%</b></span>
                {/* <InputNumber
                readOnly
                //onChange={updateDownpaymentAmt}
                defaultValue={0}
                  controls={false}
                  precision={2}
                  addonAfter="%"
                  style={{
                    width: "100%",
                  }}
                /> */}
              </Form.Item>

              <Form.Item
                style={{ width: "100%" , fontWeight:"bold"}}
                label="Actual Down Payment Amount"
                name="down_payment_actual_amt"
                rules={[
                  {
                    required: form.getFieldsValue("down_payment_actual_amt")>0,
                    message: "Down Payment Amount is Required",
                  },
                  {validator:LTVRule}
                  
                ]}
              >
                 <InputNumber
                 defaultValue={0}
                  onChange={updateDownpaymentPer}
                  precision={2}
                  style={{ width: "100%" }}
                  formatter={(value) =>
                    `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  placeholder="Down Payment Amount"

                />
              </Form.Item>
            </div>


            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              
              <Form.Item>
               <Radio.Group onChange={onChange} value={checked} style={{width:"100%", display: "flex", flexDirection: "column",
                }} >
      <Radio style={{ fontWeight:"bold", marginTop:"5px", marginBottom:"5px"}} value={true}>New Vehicle (Untitled and 599 miles or less)</Radio>
      <Radio style={{ fontWeight:"bold" , marginTop:"5px", marginBottom:"5px" }} value={false}>Used Vehicle (Titled or has 600 or more miles, even if untitled)</Radio>
      
     
    </Radio.Group>
    </Form.Item>
             
                <Form.Item
                  style={{ width: "100%", fontWeight:"bold" }}
                  label="Total Loan Amount"
                  tooltip = "Total Selling Price - Actual Down Payment"
                >
                  {/* <InputNumber
                  readOnly={true}
                    style={{ width: "100%" }}
                    formatter={(value) =>
                      `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  /> */}
                   <span className="ant-form-text"><b>{formatAmount(tla)}</b></span>
                </Form.Item>
              
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              
              {/* <div style={{width:"100%", display: "flex",
                flexDirection: "row",}}>
              <Checkbox
                style={{ alignSelf: "center", fontWeight:"bold" }}
                checked={checked}
                onChange={onChange}
              >
                New Vehicle
              </Checkbox>
              <Checkbox
                style={{  alignSelf: "center" , fontWeight:"bold"}}
                checked={checkedUsed}
                onChange={onChangeUsed}
              >
                Used Vehicle
              </Checkbox>
              </div> */}
              <Form.Item
                style={{ width: "100%", fontWeight:"bold" }}
                label="Mileage"
                name="mileage"
                rules={[
                  {
                    required: true,
                    message: "Mileage is required",
                  },
                  { validator: MileageRule },
                ]}
              >
                <InputNumber style={{width:"100%"}} type="text" placeholder="Mileage" addonAfter="miles"/>
              </Form.Item>
              <Form.Item
                style={{ width: "100%", fontWeight:"bold" }}
                label="VIN"
                name="vin_number"
                rules={[
                  {
                    required: true,
                    message: "VIN is required",
                  },
                  { validator: VINValidator },
                ]}
              >
                <Input
                  style={{
                    width: "100%",
                  }}
                  placeholder="VIN"
                  onChange={handleVinChange}
                />
              </Form.Item>
              
              
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              {checked? (
                <Form.Item
                  style={{ width: "100%" , fontWeight:"bold"}}
                  label="MSRP"
                  name="msrp"
                  rules={[
                    {
                      required: checked,
                      message: "MSRP is required",
                    },
                    { validator: LTVRule },
                    
                    
                  ]}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    formatter={(value) =>
                      `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  />
                </Form.Item>
              ) : (
                <Form.Item
                  style={{ width: "100%", fontWeight:"bold" }}
                  label="KBB Retail Value"
                  name="kbb"
                  rules={[
                    {
                      required: !checked,
                      message: "KBB Retail Value is required",
                    },
                    { validator: LTVRule },
                  ]}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    formatter={(value) =>
                      `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  />
                </Form.Item>
              )}
              
              <Form.Item
                style={{ width: "100%", fontWeight:"bold" }}
                label="Year"
                name="year"
                rules={[
                  {
                    required: true,
                    message: "Year is required",
                  },
                ]}
              >
                <Input  readOnly={true} span={2} type="text" placeholder="Year"/>
                {/* <Select
                
                  style={{
                    width: "100%",
                  }}
                  defaultValue="Select"
                  options={years}
                /> */}
              </Form.Item>

              
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              
              <Form.Item
                style={{ width: "100%", fontWeight:"bold" }}
                label="Make"
                name="make"
                rules={[
                  {
                    required: true,
                    message: "Make is required",
                  },
                ]}
              >
                <Input readOnly={true} span={2} type="text" placeholder="Make" />
              </Form.Item>

              <Form.Item
                style={{ width: "100%" , fontWeight:"bold"}}
                label="Model"
                name="model"
                rules={[
                  {
                    required: true,
                    message: "Model is required",
                  },
                ]}
              >
                <Input readOnly={true} type="text" placeholder="Model" />
              </Form.Item>
            </div>

            {loanData?.backend_dollars>0 &&
              <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Form.Item
              readOnly={true}
                style={{ width: "100%" , fontWeight:"bold"}}
                label="Backend Product Available"
              >
                {/* <InputNumber
                value={loanData?.backend_dollars}
                  style={{ width: "100%" }}
                  formatter={(value) =>
                    `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  placeholder="Backend Product Avilable"
                /> */}
                 <span className="ant-form-text"><b>{formatAmount(loanData.backend_dollars)}</b></span>
              </Form.Item>

              <Form.Item
                style={{ width: "100%" , fontWeight:"bold"}}
                label="Actual Backend Product"
                name="backend_dollars"
                rules={[
                  {
                    required: true,
                    message: "Actual Backend Product is required",
                  },
                ]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  formatter={(value) =>
                    `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  placeholder="Actual Backend Product"
                />
              </Form.Item>
            </div>
}


            <br />

            {/* <Button type="primary" onClick={checkEligibility}>
              Check Eligibility
            </Button> */}

            
            <br />
            <br />
            {/* <Tag
              style={{ display: "flex", justifyContent: "space-around" }}
              color="geekblue"
            >
              Total Finance Amount Should Not Exceed The Max Loan Amount
            </Tag> */}
          </div>
          <br />
          <Button
            type="primary"
            disabled={!eligible}
            style={{ float: "right" }}
            htmlType="submit"
          >
            GO TO UPLOAD DOCUMENTS ➡️
          </Button>

          <Link
            style={{ float: "right", marginRight: "2px" }}
            to={"/transaction"}
          >
            <Button>Back</Button>
          </Link>
        </Form>
}
        <br />
      </>
      {/* // ) : (
      //   <div style={{ backgroundColor: "#F3F3F3", padding: 15 }}>
      //     <Descriptions>
      //       <Descriptions.Item label="Approved Amount">
      //         $85,400
      //       </Descriptions.Item>
      //       <Descriptions.Item label="Total Finance Amount">
      //         $100,400
      //       </Descriptions.Item>
      //       <Descriptions.Item>
      //         <Tag color="geekblue">Eligible</Tag>
      //       </Descriptions.Item>
      //       <Descriptions.Item label="Make">BMW</Descriptions.Item>
      //       <Descriptions.Item label="Model">X5</Descriptions.Item>
      //       <Descriptions.Item label="Year">2019 </Descriptions.Item>
      //     </Descriptions>
      //   </div>
      // )} */}
      <br />
    </>
  );
};

export default FinanceAmount;
