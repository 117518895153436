const initialState = {
  all: [],
  history:[],
  currentTransactionId: null,
  transaction: null,
};

export default function transactionReducer(state = initialState, action) {
  switch (action.type) {
    case "SET_TRANSACTION_ID":
      return {
        ...state,
        currentTransactionId: action.payload,
      };
    case "SET_TRANSACTIONS":
      return {
        ...state,
        history: action.payload,
      };
    case "SET_CURRENT_TRANSACTION":
      return {
        ...state,
        transaction: action.payload,
      };

      case "SET_ALL_TRANSACTIONS":
      return {
        ...state,
        all: action.payload,
      };
      case "SET_MMY":
      return {
        ...state,
        transaction:action.payload,
      };
      case "RESET_TRANSACTION":
      return {
        ...state,
        transaction:null,
        currentTransactionId:null,
      };

    default:
      return state;
  }
}
