const initialState = {
  all: [],
  current: false,
};

export default function dealerHostReducer(state = initialState, action) {
  switch (action.type) {
    case "SET_DEALERHOST_DATA":
      return {
        ...state,
        all: action.payload,
      }
    case "SET_CURRENT_DEALERHOST_DATA":
      return {
        ...state,
        current: action.payload,
      }

    default:
      return state;
  }
}
