import React from "react";
import {
  CreditCardOutlined,
  FileDoneOutlined,
  DollarCircleOutlined,
  LoadingOutlined,
  CloudUploadOutlined,
  SolutionOutlined,
} from "@ant-design/icons";
import { Button, Descriptions, Steps } from "antd";
import { Typography } from "antd";
import { useNavigate } from "react-router-dom";
import waiting from "../../images/FP_Waiting.svg";
import check from "../../images/checkmark1.svg";
import { useDispatch, useSelector } from "react-redux";
import { sendSubmitRequest } from "../../actions/Actions";
import Title from "antd/es/typography/Title";
import axios from "axios";
import { formatAmount } from "../../util";
const { Text } = Typography;


const Confirmation = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const transaction = useSelector((state) => state.transaction);
  const loanData =useSelector((state) => state.loanData);
  let steps = [
    {
      title: <span style={{color:"#33CB2B", fontWeight:"bold"}}>Loan Details</span>,
      status: "finish",
      icon: <CreditCardOutlined style={{color:"#33CB2B"}}/>,
    },
    {
      title: <span style={{color:"#33CB2B", fontWeight:"bold"}}>Funding Details</span>,
      status: "finish",
      icon: <DollarCircleOutlined style={{color:"#33CB2B"}}/>,
    },
    {
      title: <span style={{color:"#33CB2B", fontWeight:"bold"}}>Upload Documents</span>,
      status: "finish",
      icon: <CloudUploadOutlined style={{color:"#33CB2B"}}/>,
    },
    {
      title: <span style={{color:"#33CB2B", fontWeight:"bold"}}>Transaction Summary</span>,
      status: "finish",
      icon: <SolutionOutlined style={{color:"#33CB2B"}} />,
    },
    {
      title: <span style={{color:(transaction.transaction.status === "Completed" ?"#33CB2B":"#1677ff"), fontWeight:"bold"}}>Buyers Approval</span>,
      status: "process",
      icon: <FileDoneOutlined style={{color:(transaction.transaction.status === "Completed" ?"#33CB2B":"#1677ff")}} />,
    },
  ];

  const handleClick = () => {
    navigate("/");
  };
  const handleResend = () => {
    dispatch(sendSubmitRequest({"id":transaction.currentTransactionId}))
  };

  return (
    <>
      <Steps items={steps} />

      {transaction.transaction &&
      transaction.transaction.status === "Completed" ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "#F3F3F3",
            padding: 15,
          }}
        >
          <img src={check} height="100px" alt="logo" />
          <Text>Buyer Approved Your Transaction of {formatAmount(transaction?.transaction?.amount)}</Text>
          <Text>Your Loan Funding is In Progress by {loanData?.lenderNm}</Text>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "#F3F3F3",
            padding: 15,
          }}
        >
          <img src={waiting} height="100px" alt="logo" />
          <br />
          <Title level={4}>Please ask the buyer to approve the deal in their phone. An email has been sent to the buyer. </Title>
          <br />
          {/* <Button type="primary" onClick={handleResend}>Resend</Button> */}
        </div>
      )}

      <br />
      <Button type="primary" onClick={handleClick} style={{ float: "right" }}>
      Close
      </Button>
  {transaction.transaction.status !== "Completed" && <><Button type="primary" onClick={handleResend} style={{ float: "right",  marginRight:"5px" }}>
    Resend Request
  </Button>
    <Button onClick={()=> navigate("/summary")} style={{ float: "right",  marginRight:"5px" }}>
      Back
    </Button>
    </>}
      {process.env.REACT_APP_ENV === 'DEV' && <a href onClick={()=>axios.get(process.env.REACT_APP_BASE_URL+`/api/submitTransaction?id=${transaction.currentTransactionId}`)} style={{  }}>*</a> }
    </>
  );
};

export default Confirmation;
