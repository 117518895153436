const initialState = {
  requestSent:false,
  loanAccess: false,
  intrestRates:[],
  guestLoanAccess:{
  }
};

export default function requestEntryReducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_REQUEST_SENT':
      return{
        ...state,
        requestSent: action.payload
      };
    case 'SET_LOAN_ACCESS':
      return{
        ...state,
        loanAccess: action.payload
      };
    case 'RESET_LOAN_ACCESS_REQUEST_SENT':
      return{
        initialState
      };
    case 'SET_INTREST_RATES_DATA':
      return{
        ...state,
        intrestRates: action.payload
      };

    default:
      return state;
  }
}