import React, { useEffect, useState } from "react";
import {
  CreditCardOutlined,
  FileDoneOutlined,
  DollarCircleOutlined,
  CloudUploadOutlined,
  SolutionOutlined,
} from "@ant-design/icons";
import { Button, Form, Select, Steps, Upload, message } from "antd";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { getDocuments } from "../../actions/Actions";
import "./UploadDocuments.css";



const items = [
  {
    label: "Proof of Income ",
    value: "0",
  },
  // {
  //   label: "Option 2",
  //   value: "1",
  // },
  // {
  //   label: "Option 3",
  //   value: "3",
  // },
];

const UploadDocuments = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const transaction = useSelector((state) => state.transaction);
  const documents = useSelector((state) => state.documents.urls);
  const loanData =useSelector((state) => state.loanData);

  let steps = [
    {
      title: <span style={{color:"#33CB2B", fontWeight:"bold"}}>Loan Details</span>,
      status: "finish",
      icon: <CreditCardOutlined style={{color:"#33CB2B"}}/>,
    },
    {
      title: <span style={{color:"#33CB2B", fontWeight:"bold"}}>Funding Details</span>,
      status: "finish",
      icon: <DollarCircleOutlined style={{color:"#33CB2B"}}/>,
    },
    {
      title: <span style={{color:"#1677ff", fontWeight:"bold"}}>Upload Documents</span>,
      status: "process",
      icon: <CloudUploadOutlined />,
    },
    {
      title: "Transaction Summary",
      status: "wait",
      icon: <SolutionOutlined />,
    },
    {
      title: "Buyers Approval",
      status: "wait",
      icon: <FileDoneOutlined />,
    },
  ];
 
  useEffect(() => {
    if (transaction.currentTransactionId)
      dispatch(getDocuments(transaction.currentTransactionId));
  }, []);

  const transactionId = useSelector(
    (state) => state.transaction.currentTransactionId
  );

  const onFinish = (values) => {
    console.log("Success:", values);
    navigate("/summary");
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const [other, setOther] = useState("other");
  const onChange = (value) => {
    console.log("INSIDE OnCHANGE");
    setOther(value);
  };

  console.log("other", other);

  const [fileListInsuranceCard, setFileListInsuranceCard] = useState([]);
  console.log(fileListInsuranceCard);

  const onChangeInsuranceCard = ({ fileList: newFileList }) => {
    setFileListInsuranceCard(newFileList);
  };
  const [fileListDriversLicense, setFileListDriversLicense] = useState([]);
  console.log(fileListDriversLicense);

  const onChangeDriversLicense = ({ fileList: newFileList }) => {
    setFileListDriversLicense(newFileList);
  };

  const [fileListPurchaseAgreement, setFileListPurchaseAgreement] = useState(
    []
  );
  console.log(fileListPurchaseAgreement);

  const onChangePurchaseAgreement = ({ fileList: newFileList }) => {
    setFileListPurchaseAgreement(newFileList);
  };
  const [fileListLeinReciept, setFileListLeinReceipt] = useState(
    []
  );
  console.log(fileListLeinReciept);

  const onChangeLeinReciept = ({ fileList: newFileList }) => {
    setFileListLeinReceipt(newFileList);
  };

  const [fileListOthers, setFileListOthers] = useState([]);
  console.log(fileListOthers);

  const onChangeOthers = ({ fileList: newFileList }) => {
    setFileListOthers(newFileList);
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const handleCustomRequest = async ({ action, file, onSuccess, onError }) => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      const response = await axios.post(action, formData, {
        headers: {
          Authorization: `Bearer 197749da-f7b1-4d77-86a9-08c76052e6b0`,
        },
      });

      if (response.status === 200) {
        message.success("File uploaded successfully");
        console.log("RESPONSE DATA", response.data);
        onSuccess(response.data, file);
      } else {
        message.error("Error uploading file");
        onError(response);
      }
    } catch (error) {
      message.error("Error uploading file");
      onError(error);
    }
  };

  const handleRemove = async (file) => {
    const uploadDetails = file.response
      ? file.response.url.split("/")
      : file.uid.split("/");
    console.log(uploadDetails);

    await axios.delete(
      process.env.REACT_APP_BASE_URL +
        `/api/documents?id=${uploadDetails[0]}&docName=${uploadDetails[1]}&fileName=${uploadDetails[2]}`,
      {
        headers: {
          Authorization: `Bearer 197749da-f7b1-4d77-86a9-08c76052e6b0`,
        },
      }
    );
  };

  useEffect(() => {
    setFileListInsuranceCard(documents.insuranceCard);
    setFileListDriversLicense(documents.driversLicense);
    setFileListPurchaseAgreement(documents.purchaseAgreement);
    setFileListOthers(documents.other);
    setFileListLeinReceipt(documents.lr);
  }, [documents]);

  // const validateUpload = (_) => {
  //   if (fileListInsuranceCard.length === 0) {
  //     return Promise.reject('Insurance Card is required');
  //   }
  //   return Promise.resolve();
  // };
  // const validateUploadDL = (_) => {
  //   if (fileListDriversLicense.length === 0) {
  //     return Promise.reject('Drivers License is required');
  //   }
  //   return Promise.resolve();
  // };
  const validateUploadPA = (_) => {
    if (fileListPurchaseAgreement.length === 0) {
      return Promise.reject('Purchase Agreement is required');
    }
    return Promise.resolve();
  };
  // const validateUploadLR = (_) => {
  //   if (fileListLeinReciept.length === 0) {
  //     return Promise.reject('Lien Receipt / Application For Title is required');
  //   }
  //   return Promise.resolve();
  // };

  return (
    <>
      <Steps items={steps} />
      <br />
      <Form layout="vertical"  onFinish={onFinish}
          onFinishFailed={onFinishFailed}>
        <div className="upload-block">
          <Form.Item
            className="title"
            label="Insurance Card"
            type="file"
            name="insurance_card"
            // rules={[
            //   {
            //     validator: validateUpload,
            //   },
            // ]}
          >
            <Upload
              className="upload"
              customRequest={handleCustomRequest}
              action={
                process.env.REACT_APP_BASE_URL +
                `/api/uploadDocuments?id=${transactionId}&docName=insuranceCard`
              }
              listType="picture-card"
              fileList={fileListInsuranceCard}
              onChange={onChangeInsuranceCard}
              onPreview={onPreview}
              onRemove={handleRemove}
            >
              {fileListInsuranceCard.length < 5 && "+ Upload"}
            </Upload>
          </Form.Item>
        </div>
        <br/>
        <div className="upload-block">
          <Form.Item
            className="title"
            label="Driver License"
            name="drivers_license"
            // rules={[
            //   {
            //     validator: validateUploadDL,
            //   },
            // ]}
          >
            <Upload
              className="upload"
              customRequest={handleCustomRequest}
              action={
                process.env.REACT_APP_BASE_URL +
                `/api/uploadDocuments?id=${transactionId}&docName=driversLicense`
              }
              listType="picture-card"
              fileList={fileListDriversLicense}
              onChange={onChangeDriversLicense}
              onPreview={onPreview}
              onRemove={handleRemove}
            >
              {fileListDriversLicense.length < 5 && "+ Upload"}
            </Upload>
          </Form.Item>
        </div>
        <br/>
        <div className="upload-block">
          <Form.Item
            className="title req"
            label="Purchase Agreement"
            name="purchase_agreement"
            rules={[
              {
                validator: validateUploadPA,
              },
            ]}
          >
            <Upload
              className="upload"
              customRequest={handleCustomRequest}
              action={
                process.env.REACT_APP_BASE_URL +
                `/api/uploadDocuments?id=${transactionId}&docName=purchaseAgreement`
              }
              listType="picture-card"
              fileList={fileListPurchaseAgreement}
              onChange={onChangePurchaseAgreement}
              onPreview={onPreview}
              onRemove={handleRemove}
            >
              {fileListPurchaseAgreement.length < 5 && "+ Upload"}
            </Upload>
          </Form.Item>
        </div>
        <br/>
        <div className="upload-block">
          <Form.Item
            className="title"
            label="Lien Receipt / Application For Title"
            name="lein_recept"
            // rules={[
            //   {
            //     validator: validateUploadLR,
            //   },
            // ]}
          >
            <Upload
              className="upload"
              customRequest={handleCustomRequest}
              action={
                process.env.REACT_APP_BASE_URL +
                `/api/uploadDocuments?id=${transactionId}&docName=leinReciept`
              }
              listType="picture-card"
              fileList={fileListLeinReciept}
              onChange={onChangeLeinReciept}
              onPreview={onPreview}
              onRemove={handleRemove}
            >
              {fileListPurchaseAgreement.length < 5 && "+ Upload"}
            </Upload>
          </Form.Item>
        </div>
        <br/>
        <div className="upload-block">
          <Form.Item className="title" label="Others" name="insurance_card">
            <Select
              style={{
                width: 200,
                display: "block",
              }}
              value={other}
              options={items}
              onChange={onChange}
            />

            <Upload
              className="upload"
              customRequest={handleCustomRequest}
              action={
                process.env.REACT_APP_BASE_URL +
                `/api/uploadDocuments?id=${transactionId}&docName=${other}`
              }
              listType="picture-card"
              fileList={fileListOthers}
              onChange={onChangeOthers}
              onPreview={onPreview}
              onRemove={handleRemove}
            >
              {fileListOthers.length < 5 && "+ Upload"}
            </Upload>
          </Form.Item>
        </div>

        <br />
        <Button
          type="primary"
          htmlType="submit"
          style={{ float: "right" }}
        >
          GO TO TRANSACTION SUMMARY ➡️
        </Button>
     
      <Button style={{ float: "right", marginRight: "2px" }}>
        <Link to={"/finance"}>Back</Link>
      </Button>
      </Form>
    </>
  );
};

export default UploadDocuments;
