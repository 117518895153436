import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import React from "react";
import Navbar from "./Navbar/Navbar";
import LoadingOverlay from "./LoadingOverlay/LoadingOverlay";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";


export const AuthenticationGuard = ({ component }) => {
  const { isAuthenticated } = useAuth0();
  // const { loginWithRedirect } = useAuth0();
  // const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.loading);
  const currentDealerhost = useSelector((state) => state.dealerHosts.current);
  console.log("isLoading",isLoading)
  console.log("currentDealerhost",currentDealerhost)

  // useEffect(() => {
  //   dispatch({
  //     type: "RESET_LOADING"
  //   });
  //   async function handleLogin () {
  //     await loginWithRedirect({
  //       connection: 'email', // Specify the connection name for passwordless email
  //       scope: 'link'
        
  //     });
     
  //   };
  //   if (!isAuthenticated) {
  //     dispatch({
  //       type: "SET_LOADING"
  //     });
  //     handleLogin();
  //   }
  // }, [isAuthenticated,loginWithRedirect]);
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => (
      <div className="page-layout">
       <img src='https://cdn.auth0.com/blog/hello-auth0/loader.svg' alt="Loading..." />
      </div>
    ),
  });

  return  <div>{(!isAuthenticated || !currentDealerhost) &&<LoadingOverlay />}<Navbar><Component /> </Navbar></div>;
  //return  <Navbar><Component /> </Navbar>;
};