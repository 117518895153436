import moment from "moment";

export function  formatAmount  (value){
    return `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }

export function   formatCreditCardNumber (value) {
    if (!value) return "";

    // Remove non-digit characters from the input
    const digitsOnly = value.toString().replace(/\D/g, "");

    // Split the credit card number into groups of 4 digits
    const groups = [];
    for (let i = 0; i < digitsOnly.length; i += 4) {
      groups.push(digitsOnly.substr(i, 4));
    }

    // Join the groups with a space between each group
    return groups.join("-");
  };

  export function formatdate (date){
   return new moment(date).format('LL')
  }

  export function parseCreditCardNumber  (value) {
    // Remove non-digit characters from the input
    return value.toString().replace(/\D/g, "").slice(0, 16);
  };