import React, { useEffect } from "react";
import { Link, Routes, Route, useLocation } from "react-router-dom";
import "./Navbar.css";
import {
  UserOutlined,
  PoweroffOutlined,
  HistoryOutlined,
  DashboardOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import { Button, Layout, Menu, Tooltip, message, theme } from "antd";
import Dashboard from "../Dashboard/Dashboard";
import logo from "../../images/FP_Logo.png";
import Transaction from "../Transaction/Transaction";
import FinanceAmount from "../FinanceAmount/FinanceAmount";
import UploadDocuments from "../UploadDocuments/UploadDocuments";
import TransactionSummary from "../TransactionSummary/TransactionSummary";
import TransactionHistory from "../TransactionHistory/TransactionHistory";
import Team from "../Team/Team";
import Invite from "../Invite/Invite";
import Confirmation from "../Confirmation/Confirmation";
import { AuthenticationGuard } from "../authentication-guard";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentDealerHost } from "../../actions/Actions";
import DealershipSignup from "../DealershipSignup/DealershipSignup";
import { useState } from "react";
// import { withStore } from "../../store";
const { Header, Content, Sider } = Layout;

const Navbar = ({children}) => {
  const { logout, user, isAuthenticated } = useAuth0();
  const [hovered, setHovered] = useState(false);
  const currentDealerhost = useSelector((state) => state.dealerHosts.current);
  const loanData =useSelector((state) => state.loanData);
  const dispatch = useDispatch();
  const location = useLocation();
  console.log(location);

  
    
  const buttonStyle = {
    color:"#33CB2B",
    borderColor: hovered ? '#33CB2B' : "", // Set the border color based on hover state
    transition: 'border-color 0.3s', // Add a smooth transition effect
  };


  const sidebarMenu = [
    {
      key: `dashboard`,
      icon: React.createElement(DashboardOutlined),
      label: <Link to={"/"}>My Dashboard</Link>,
    },
    {
      key: `history`,
      icon: React.createElement(HistoryOutlined),
      label: <Link to={"/transaction-history"}>Transaction History</Link>,
    },
    {
      key: `organisation`,
      icon: React.createElement(TeamOutlined),
      label: `My Organisation`,
      children: [
        { key: `team`, label: <Link to={"/team"}>My Team</Link> },
        (currentDealerhost?.dealer_host_title === "Supervisor" || currentDealerhost?.dealer_host_title === "Administrator") && {
          key: `invite`,
          label: <Link to={"/invite"}>Invite</Link>,
        },
      ],
    },
  ];

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getCurrentDealerHost(user?.email, handleLogout));
    }
  }, [user, isAuthenticated]);

  const handleLogout = () => {
    localStorage.clear();
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  // useEffect(() => {
  //   if (currentDealerhost === undefined && window.location.pathname !=="/signup") {
  //     console.log(window.location.pathname)
  //     message.error(
  //       "No user found for the email. Please contact your supervior or signup your dealership",
  //       5
  //     );
  //     // setInterval(logout({ logoutParams: {
  //     //   returnTo: process.env.REACT_APP_BASE_URL+"/signup",
  //     // },}),5000);
  //     setTimeout(() => {
  //       // window.open("/signup");
  //       handleLogout()
  //     }, 5000);
  //    // logout();
  //   }
  // }, [currentDealerhost]);


  const name =()=>{
    let name = currentDealerhost?.dealer_host_nm?.split(" ")[0];
    let finalName = name?.charAt(0).toUpperCase() + name?.slice(1) +" ";
    return finalName;
  }
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  return (
    <>
      {/* {location.pathname === "/signup" ? (
        <Routes>
          <Route
            path="/"
            element={<AuthenticationGuard component={Dashboard} />}
          />
          <Route
            path="/transaction-history"
            element={<AuthenticationGuard component={TransactionHistory} />}
          />
          <Route
            path="/transaction"
            element={<AuthenticationGuard component={Transaction} />}
          />
          <Route
            path="/team"
            element={<AuthenticationGuard component={Team} />}
          />
          <Route
            path="/invite"
            element={<AuthenticationGuard component={Invite} />}
          />
          <Route
            path="/finance"
            element={<AuthenticationGuard component={FinanceAmount} />}
          />
          <Route
            path="/upload-documents"
            element={<AuthenticationGuard component={UploadDocuments} />}
          />
          <Route
            path="/summary"
            element={<AuthenticationGuard component={TransactionSummary} />}
          />
          <Route
            path="/confirmation"
            element={<AuthenticationGuard component={Confirmation} />}
          />
          <Route path="/signup" Component={DealershipSignup} />
        </Routes>
      ) : ( */}
        <Layout>
          <Header className="header" >
            <div className="logo">
              <Link to={"/"}>
                <img height={70} alt="logo" src={logo} />
              </Link>
            </div>

            {loanData?.logo_url && <img  height={65} alt="cu_logo" src={loanData?.logo_url} />}
           
<div style={{display:"flex" , justifyContent:"flex-end"}}>
            <div style={{ marginRight:'5px'}}>
            <label style={{fontSize:"large"}}> Welcome, <b>{name()} </b> {/* of <b>{currentDealerhost.dealer_nm}</b> */} </label>
            
            </div>
            <div>
            <Tooltip title="Logout">
          <Button onClick={handleLogout}   style={buttonStyle}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}>
            {" "}
            <PoweroffOutlined twoToneColor="#33CB2B" />{" "}
          </Button>{" "}
        </Tooltip>
            </div>
            </div>
            {/* <Menu theme="light" mode="horizontal" items={headerMenu} /> */}
          </Header>
          <Layout>
            <Sider
              width={200}
              style={{
                background: colorBgContainer,
              }}
            >
              <Menu
                mode="inline"
                defaultSelectedKeys={["dashboard"]}
                style={{
                  height: "100%",
                  justifyContent: "space-between",
                  borderRight: 0,
                }}
                items={sidebarMenu}
              />
            </Sider>
            <Layout
              style={{
                padding: "0 24px 24px",
              }}
            >
              {/* <Breadcrumb
              style={{
                margin: '16px 0',
              }}
            >
              <Breadcrumb.Item>Home</Breadcrumb.Item>
              <Breadcrumb.Item>List</Breadcrumb.Item>
              <Breadcrumb.Item>App</Breadcrumb.Item>
            </Breadcrumb> */}
              <Content
                style={{
                  padding: 24,
                  margin: 0,
                  minHeight: 600,
                  background: colorBgContainer,
                }}
              >
                {/* <Routes>
                  <Route
                    path="/"
                    element={<AuthenticationGuard component={Dashboard} />}
                  />
                  <Route
                    path="/transaction-history"
                    element={
                      <AuthenticationGuard component={TransactionHistory} />
                    }
                  />
                  <Route
                    path="/transaction"
                    element={<AuthenticationGuard component={Transaction} />}
                  />
                  <Route
                    path="/team"
                    element={<AuthenticationGuard component={Team} />}
                  />
                  <Route
                    path="/invite"
                    element={<AuthenticationGuard component={Invite} />}
                  />
                  <Route
                    path="/finance"
                    element={<AuthenticationGuard component={FinanceAmount} />}
                  />
                  <Route
                    path="/upload-documents"
                    element={
                      <AuthenticationGuard component={UploadDocuments} />
                    }
                  />
                  <Route
                    path="/summary"
                    element={
                      <AuthenticationGuard component={TransactionSummary} />
                    }
                  />
                  <Route
                    path="/confirmation"
                    element={<AuthenticationGuard component={Confirmation} />}
                  />
                  <Route path="/signup" Component={DealershipSignup} />
                </Routes> */}
                {children}
              </Content>
            </Layout>
          </Layout>
        </Layout>
      {/* )} */}
    </>
  );
};

export default Navbar;
