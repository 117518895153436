import React, { useEffect, useState } from "react";
import {
  Checkbox,
  Table,
  Tag,
  Typography,
} from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getTransaction } from "../../actions/Actions";
import { formatCreditCardNumber, formatdate } from "../../util";
import TSModal from "../TSModal/TSModal";


const TransactionHistory = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const transaction = useSelector((state) => state.transaction);
  const [transactionData, setTransactionData] = useState(transaction.history?transaction.history:[]);
  const currentDealerhost = useSelector((state) => state.dealerHosts.current);
  const handleClick = () => {
    navigate("/transaction");
  };
  const handleShowModal = (record) => {
    console.log("handle show clicked")
    console.log(record);
    setRowData(record);
   showModal()
  };
  // const rowProps = (record) => ({
  //   onClick: () => handleShowModal(record),
  // });

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [rowData, setRowData] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    dispatch({
      type: "RESET_LOAN_DATA"
    });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    dispatch({
      type: "RESET_LOAN_DATA"
    });
  };


  const columns = [
    {
      title: "Client Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Credit Card ID",
      dataIndex: "loan_id",
      key: "loan_id",
      render: (_, { loan_id }) => <>{formatCreditCardNumber(loan_id)}</>,
    },
    {
      title: "Make/Model/Year",
      dataIndex: "mmy",
      key: "mmy",
      render: (_, { mmy }) => <>{mmy === "//" ? "NA" : mmy}</>,
    },
    {
      title: "Sale Initiated By",
      dataIndex: "dealer_host_nm",
      key: "dealer_host_nm",
    },
    {
      title: "Start Date",
      dataIndex: "created_ts",
      key: "created_ts",
      render: (_, { created_ts }) => <>{formatdate(created_ts)}</>,
    },
    {
      title: "Completion Date",
      dataIndex: "approval_ts",
      key: "approval_ts",
      render: (_, { approval_ts }) => <>{approval_ts!==null?formatdate(approval_ts): ""}</>,
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (_, record) => (
        <>
        {record.status === "Completed"?(
           <Tag 
           color="darkgreen"
           key={record.status}
         >
           <a href onClick={()=>handleShowModal(record)}>{record.status.toUpperCase()}</a>
         </Tag>
        ):(record.status === 'Approved'?( <Tag 
          color="green"
          key={record.status}
        >
          <a href onClick={()=>handleShowModal(record)}>{record.status.toUpperCase()}</a>
        </Tag>):(
          <Tag 
          color={
            record.status === "Lost"
              ? "volcano"
              : "geekblue"
          }
          key={record.status}
        >
          {record.status.toUpperCase()}
        </Tag>)
        )}
         
        </>
      ),
    },
  ];

  
  let tableKey = Date.now();

  useEffect(() => {
    if(currentDealerhost && currentDealerhost.dealer_host_id)
    dispatch(getTransaction({ dealerhost_id: currentDealerhost.dealer_host_id }));
  }, [currentDealerhost]);

  useEffect(() => {
    setTransactionData(transaction.history?transaction.history:[]);
  }, [transaction.history]);


  const options = [
    {
      label: "Completed",
      value: "Completed",
    },
    {
      label: "Approved",
      value: "Approved",
    },
    {
      label: "InProgress",
      value: "InProgress",
    },
    {
      label: "Lost",
      value: "Lost",
    },
  ];

  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };

  const handleFilter = (checkedValues) => {
    tableKey = Date.now();
    console.log("checkedValues",checkedValues)
    if(checkedValues.length>0 && currentDealerhost && currentDealerhost.dealer_host_id){
      
   setTransactionData(  transaction.history.filter( (el)=>{
      return  checkedValues.includes(el.status)
    }))
  }
  else{
    setTransactionData(transaction.history?transaction.history:[]);
  }
    console.log(transactionData)
  };

  
  return (
    <>
{isModalVisible&&<TSModal isModalVisible={isModalVisible} rowData={rowData}
    handleOk={handleOk}
    handleCancel={handleCancel}/>}
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
        {/* Heading */}
        <Typography.Title level={5} style={{ marginTop: 10 }}>
          Transaction History
        </Typography.Title>
        {/* <div>
          <Input style={{ width: 350, marginRight: 10 }} placeholder="Search" />
          <Button type="primary" onClick={handleClick}>
            Search
          </Button>
        </div> */}
      </div>
      {/* Filter */}
      <div style={{ marginTop: 20 }}>
        <span style={{ marginRight: 30 }}>View </span>
        <Checkbox.Group options={options} onChange={handleFilter}/>

        {/* <DatePicker style={{ marginLeft: 50 }} onChange={onChange} /> */}
      </div>
      {/* Data table */}
      <Table
        style={{ marginTop: 30 }}
        columns={columns}
        rowKey={(transactionData)=> transactionData.id}
        dataSource={transactionData}
        // onRow={(record) => ({
        //   ...rowProps(record),
        // })}
      />
    </>
  );
};

export default TransactionHistory;
