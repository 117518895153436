const initialState = false;

export default function loadingReducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_LOADING':
      return true
    case 'RESET_LOADING':
      return false

    default:
      return state;
  }
}