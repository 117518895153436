import { useEffect } from "react";
import "./App.css";
import  Navbar  from "./components/Navbar/Navbar";
import { Route, Routes } from "react-router-dom";
import { AuthenticationGuard } from "./components/authentication-guard";
import Dashboard from "./components/Dashboard/Dashboard";
import TransactionHistory from "./components/TransactionHistory/TransactionHistory";
import Transaction from "./components/Transaction/Transaction";
import Team from "./components/Team/Team";
import Invite from "./components/Invite/Invite";
import FinanceAmount from "./components/FinanceAmount/FinanceAmount";
import UploadDocuments from "./components/UploadDocuments/UploadDocuments";
import TransactionSummary from "./components/TransactionSummary/TransactionSummary";
import Confirmation from "./components/Confirmation/Confirmation";
import DealershipSignup from "./components/DealershipSignup/DealershipSignup";
import LoanData from "./components/LoanData/LoanData";
import GuestLoanDeatils from "./components/GuestLoanDeatils/GuestLoanDeatils";


function App() {

  // useEffect(() => {
  //   const handleBeforeUnload = (event) => {
  //     event.preventDefault();
  //     event.returnValue = ''; // Required for Chrome
  //   };

  //   window.addEventListener('beforeunload', handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //   };
  // }, []);
  return (
    <div className="App">
      {/* <Navbar /> */}
      <Routes>
          <Route
            path="/"
            element={<AuthenticationGuard component={Dashboard} />}
          />
          <Route
            path="/transaction-history"
            element={<AuthenticationGuard component={TransactionHistory} />}
          />
          <Route
            path="/transaction"
            element={<AuthenticationGuard component={Transaction} />}
          />
          <Route
            path="/team"
            element={<AuthenticationGuard component={Team} />}
          />
          <Route
            path="/invite"
            element={<AuthenticationGuard component={Invite} />}
          />
          <Route
            path="/finance"
            element={<AuthenticationGuard component={FinanceAmount} />}
          />
          <Route
            path="/upload-documents"
            element={<AuthenticationGuard component={UploadDocuments} />}
          />
          <Route
            path="/summary"
            element={<AuthenticationGuard component={TransactionSummary} />}
          />
          <Route
            path="/confirmation"
            element={<AuthenticationGuard component={Confirmation} />}
          />
          <Route path="/signup" Component={DealershipSignup} />
          <Route path="/loan-data" Component={LoanData} />
          <Route path="/guest-loan-details" Component={GuestLoanDeatils} />
        </Routes>
    </div>
  );
}

export default App;
