const initialState = {
 urls:{
  driversLicense:[],
  insuranceCard: [],
  other:[],
  purchaseAgreement:[]
 }
};

export default function documentReducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_DOCUMENTS':
      return{
        ...state,
        urls: action.payload
      };

    default:
      return state;
  }
}