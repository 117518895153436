import React, { useEffect } from "react";
import { Input, Space, Table, Button, Tag, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getDealerHosts } from "../../actions/Actions";
import { formatdate } from "../../util";

const columns = [
  {
    title: "Name",
    dataIndex: "dealer_host_nm",
    key: "dealer_host_nm",
  },
  {
    title: "Email",
    dataIndex: "dealer_host_email",
    key: "dealer_host_email",
  },
  {
    title: "Role",
    dataIndex: "dealer_host_title",
    key: "dealer_host_title",
  },
  {
    title: "Date of Joining",
    dataIndex: "meta_efftv_start_dttm",
    key: "meta_efftv_start_dttm",
    render: (_, { meta_efftv_start_dttm }) => (
      <>
        {formatdate(meta_efftv_start_dttm)}
      </>
    ),
  },
  {
    title: "Status",
    key: "meta_active_status_cd",
    dataIndex: "meta_active_status_cd",
    render: (_, { meta_active_status_cd }) => (
      <>
        <Tag color={meta_active_status_cd === "Y" ? "darkgreen" : "gray"} key={meta_active_status_cd}>
          {meta_active_status_cd === 'Y'? 'Active': 'InActive'}
        </Tag>
      </>
    ),
  },
  // {
  //   title: "Action",
  //   key: "action",
  //   render: (_, record) => (
  //     <Space size="middle">
  //       <Button type="primary" color="green">
  //         {record.meta_active_status_cd === "Y" ? "Deactivate" : "Activate"}
  //       </Button>
  //     </Space>
  //   ),
  // },
];

const Team = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentDealerhost = useSelector((state) => state.dealerHosts.current);
  const handleClick = () => {
    navigate("/transaction");
  };
  const dealerHost = useSelector((state) => state.dealerHosts.all);
  console.log(dealerHost)

  //Send Dealer Id. Hardcoded right now 
  useEffect(() => {
    dispatch(getDealerHosts(currentDealerhost.dealer_id))
  }, [currentDealerhost])

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 20,
        }}
      >
        {/* Heading */}
        <Typography.Title level={5} style={{ marginTop: 10 }}>
          My Team
        </Typography.Title>
        {/*removed search */}
        {/* <div>
          <Input style={{ width: 350, marginRight: 10 }} placeholder="Search" />
          <Button type="primary" onClick={handleClick}>
            Search
          </Button>
        </div> */}
      </div>
      {/* Data table */}
      <Table columns={columns} dataSource={dealerHost} key={dealerHost.dealer_host_id} />
    </>
  );
};

export default Team;
