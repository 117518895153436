import React, { useEffect, useState } from 'react'
import { CreditCardOutlined, FileDoneOutlined, DollarCircleOutlined, LoadingOutlined, CloudUploadOutlined ,SolutionOutlined} from '@ant-design/icons';
import { Button, Space, Spin, Steps } from 'antd';
import RequestEntry from '../RequestEntry/RequestEntry';
import LoanDetails from '../LoanDetails/LoanDetails';
import { useDispatch, useSelector } from 'react-redux';
import './Transaction.css'
import { Link, useNavigate } from 'react-router-dom';
import { updateTransaction } from '../../actions/Actions';
import axios from 'axios';



const Transaction = () => {
    const requestSent = useSelector((state) => state.requestEntry.requestSent);
    const loanAccess = useSelector((state) => state.requestEntry.loanAccess);
    const transaction = useSelector((state) => state.transaction);
    const loanData =useSelector((state) => state.loanData);
    const currentDealerhost = useSelector((state) => state.dealerHosts.current);
    const [rate, setRate] = useState(false);
    const [ltv, setLTV] = useState(false);
    const [term, setTerm] = useState(false);
    const [cardId, setcardId] = useState(null);
    const navigate = useNavigate();
  const dispatch = useDispatch();
    console.log("Loan Data", loanData)
    console.log("loanAccess", loanAccess)
    console.log("transaction", transaction)
    console.log("requestSent", requestSent)
    let steps = [
      {
        title: <span style={{color:"#1677ff", fontWeight:"bold"}}>Loan Details</span>,
        status: 'process',
        icon: <CreditCardOutlined />,
      },
      {
        title: 'Funding Details',
        status: 'wait',
        icon: <DollarCircleOutlined />,
      },
      {
        title: 'Upload Documents',
        status: 'wait',
        icon: <CloudUploadOutlined />,
      },
      {
        title: 'Transaction Summary',
        status: 'wait',
        icon: <SolutionOutlined />,
      },
      {
        title: "Buyers Approval",
        status: 'wait',
        icon: <FileDoneOutlined />,
      },
    ]

    useEffect(() => {
     //rerender page on transaction update
    }, [transaction.transaction])
    

    const handleClick = () => {
      if(ltv&&rate&&term){
      dispatch(updateTransaction({ id: transaction.currentTransactionId, ltv:ltv, rate:rate ,term:term,  partial:true}));
      }
      else{
        dispatch(updateTransaction({ id: transaction.currentTransactionId, ltv:transaction?.transaction?.ltv?transaction?.transaction?.ltv:loanData.ltv, rate:transaction?.transaction?.interest_rates?transaction?.transaction?.interest_rates: loanData.preapproved_pct ,term:transaction?.transaction?.term?transaction?.transaction?.term:loanData.term,  partial:true}));
      }

      navigate("/finance");
    };

    console.log(window.location.origin)
  return (
    <>
    <Steps
    items={steps}
  />
  {!((requestSent||loanAccess) )?(
   <RequestEntry getCardId={setcardId} />
  ):
  (
    loanData&&loanAccess?( <LoanDetails data={loanData} rateActual={setRate} termActual={setTerm} ltvActual={setLTV} transaction={transaction?.transaction}/>):
    (<Space className='space' direction="vertical" style={{ width: '100%' }}>
        <Spin tip="An email has been sent to the buyer. Ask the buyer to click on the link to provide the access to loan details" size="large">
        <div className="content"  />
      </Spin>
    </Space>)
 
  )
}
{loanData?(
        <Button style={{ float: "right", marginRight: "2px" }}onClick={handleClick}  type="primary" >GO TO FUNDING DETAILS ➡️</Button>
    ):(
        <Button style={{ float: "right", marginRight: "2px" }} disabled={!loanData}>GO TO FUNDING DETAILS ➡️</Button>
      )}

<Link style={{ float: "right", marginRight: "2px" }} to={"/"}>
        <Button>Back</Button>
      </Link>

      {process.env.REACT_APP_ENV === "DEV" && !loanData && requestSent && <a href onClick={()=>axios.get(process.env.REACT_APP_BASE_URL+`/api/loanAccess?loan_id=${cardId}&&dealer_id=${currentDealerhost.dealer_id}`)} style={{  }}>*</a> }
  </>
  )
}

export default Transaction